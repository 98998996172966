import styled from '@emotion/styled'
import { Select } from 'antd'

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  
  &:not(:first-of-type) {
    margin-top: 10px;
  }
`

export const Col = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: space-between;
  
  &:not(:first-of-type) {
    margin-top: 10px;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;

  .ant-select-selection {
    border-radius: 20px;
    width: 400px;
  }

  .ant-btn-round {
    font-size: 14px;
    font-weight: bold;
  }
`
export const SelectAll = styled.div`
  font-weight: bold;
  vertical-align: center;
  line-height: 30px;
  margin-right: 15px;
`

export const SelectRandom = styled.div`
  font-weight: bold;
  line-height: 30px;
  margin-right: 15px;
  input {
    width: 50px;
    padding-left: 10px;
  }
`
export const StyledSelect = styled(Select)`
  .ant-select-selection {
    border: 1px solid #514fff;
    border-radius: 25px;
  }
  .ant-select-selection:hover {
    border-color: #514fff;
    border-width: 2px !important;
  }
  &.ant-select-focused .ant-select-selection,
  &.ant-select-selection:focus,
  &.ant-select-selection:active {
  border-color: #514fff;
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  }
  .ant-select-selection-selected-value {
    color: #000 !important;
  }
`

export const Item = styled.div`

  .ant-select-selection {
    height: 36px;
  }

/*
  .ant-select-selection {
    border-radius: 20px;
    width: 400px;
  }

  .ant-btn-round {
    font-size: 14px;
    font-weight: bold;
  }
  */
`

export const dropdown = {
  PaperProps: {
    style: {
      maxHeight: 300,
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};

export const SectionTitle = styled.p`
  text-align: start;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 500;
  color: #3a39e0;
`

export const SectionSeparator = styled.div`
  height: 2px;
  background-color: #3a39e0;
  margin-top: 40px;
`