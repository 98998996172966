import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { logout } from "../../store/auth/actions";
import { handleTrilhasLogin } from "../../store/users/actions";
import { Menu } from "antd";
import {
  StyledMenuItem,
  StyledMenuSubMenu,
  StyledSubMenu,
} from "./styles/AdminButton.styles";
import { handleWhatsappMessage } from "../../utils/contact";

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout,
      handleTrilhasLogin
    },
    dispatch
  );

const handleLogout = (e, user, delegate) => {
  e.preventDefault();
  if (user.Role === "Student") window.location = "/student";
  else window.location = "/login";
  delegate();
};

const AdminButton = ({ logout, user, selectedKeys, handleTrilhasLogin }) => {
  return (
    <Menu selectedKeys={selectedKeys} mode="horizontal">
      <StyledMenuSubMenu title={user !== null ? user.Name : ""}>
        {user !== null && user.Role !== "Student" && user.Role !== "Teacher" && (
          <StyledSubMenu title="Administração">
            {user !== null &&
              (["SuperUser", "Super"].includes(user.Role)) && (
                <StyledMenuItem key="companies">
                  <Link to="/companies">Empresas</Link>
                </StyledMenuItem>
              )}
            {user !== null &&
              (["SuperUser", "Super", "District", "Master"].includes(user.Role)) && (
                <StyledMenuItem key="users">
                  <Link to="/users">Usuários</Link>
                </StyledMenuItem>
              )}
          </StyledSubMenu>
        )}
        {user !== null &&
          (["SuperUser", "Super", "District", "Master"].includes(user.Role)) && <hr />}
        {(user.Role === "Master" ||
          user.Role === "Teacher") && (
          <StyledMenuItem key="tests_start">
            <Link to="/tests/start">Aplicação</Link>
          </StyledMenuItem>
        )}
        {user !== null && user.Role !== "Student" && (
          <StyledMenuItem key="meus_dados">
            <Link to={"/users/" + user.Id + "/meus_dados"}>Meus Dados</Link>
          </StyledMenuItem>
        )}
        {/* <StyledMenuItem key="logout">
          <Link
            to="/student"
            onClick={() => handleTrilhasLogin(user.Email)}
          >
            <span style={{ marginBottom: "10px" }}>Trilhas</span>
          </Link>
        </StyledMenuItem> */}
        <StyledMenuItem key="contato">
          <Link
            to=""
            onClick={(e) => {
              e.preventDefault();
              handleWhatsappMessage();
            }}
          >
            <span style={{ marginBottom: "10px" }}>Contato</span>
          </Link>
        </StyledMenuItem>
        <StyledMenuItem key="logout">
          <Link
            to=""
            onClick={(e) => handleLogout(e, user, logout)}
          >
            <span style={{ marginBottom: "10px" }}>Logout</span>
          </Link>
        </StyledMenuItem>
      </StyledMenuSubMenu>
    </Menu>
  );
};

AdminButton.propTypes = {
  logout: PropTypes.func.isRequired,
  handleTrilhasLogin: PropTypes.func.isRequired,
  selectedKeys: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
};

export default connect(null, mapDispatchToProps)(AdminButton);
