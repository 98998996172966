import React, { useState } from "react";
import PropTypes from "prop-types";
import { StyledSelect } from "../question/QuestionFilterHeader/styles.js";
import { Select } from "antd";

function SelectDistricts({ onChange, options = [] }) {
  const allDistricts = ["D1", "D2", "D3", "D4", "D5", "D6"];

  const [selected, setSelected] = useState([]);
  const districts = options && options.length ? options : allDistricts;
  const { Option } = Select;

  function handleChange(value) {
    if (value !== "") {
        setSelected((prevSelected) => prevSelected.filter((item) => item !== ""));
    }
    onChange(value);
    setSelected(value);
  }

  function renderOptions() {
    return districts.map((district) => (
      <Option key={district} value={district} title={district}>
        {district}
      </Option>
    ));
  }



  return (
    <StyledSelect
      mode={"multiple"}
      className="select-input"
      placeholder={"Selecione o(s) distrito(s)"}
      onChange={handleChange}
      value={selected}
      style={{ width: "100%" }}
    >
      {renderOptions()}
    </StyledSelect>
  );
}

SelectDistricts.propTypes = {
    onChange: PropTypes.func
}

export default SelectDistricts;
