import React, { Component } from "react";
import PropTypes from "prop-types";
import { Select } from "antd";
import { handleChange } from "../../utils/formHelpers";

const { Option } = Select;

class SelectDistrict extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initialValue: this.props.initialValue,
            input: ""
        };
        this.handleSelectChange = this.handleSelectChange.bind(this);
    }


    static propTypes = {
        field: PropTypes.object.isRequired,
        form: PropTypes.object.isRequired,
        user: PropTypes.object,
        initialValue: PropTypes.string,
        disabled: PropTypes.bool
    };

    districts = [
        'D1',
        'D2',
        'D3',
        'D4',
        'D5',
        'D6',
    ];

    componentDidUpdate(prevProps) {
        const { initialValue } = this.props;
        if (prevProps.initialValue !== initialValue) {
            this.setState({ initialValue: initialValue });
        }
    }

    handleSelectChange(value) {
        this.setState({ initialValue: '' }, () => 
            handleChange(this.props.form, this.props.field, value)
        );
    }

    renderOptions() {
        return this.districts.map(district => (
            <Option key={district} value={district} title={district}>
                {district}
            </Option>
        ));
    }

    render() {
        const { field, form, disabled } = this.props;
        const { initialValue } = this.state;

        return (
            <Select
                className="select-input"
                value={initialValue || field.value || undefined}
                onChange={this.handleSelectChange}
                showSearch 
                style={{ width: "100%", marginBottom: "2rem" }} 
                placeholder={"Distrito"}
                disabled={disabled}
            >
                {this.renderOptions()}
            </Select>
        )
    }
}

export default SelectDistrict;