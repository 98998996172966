import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  fetchResultExam,
  fetchReadAndWriting,
  fetchReadingAndWritingCSVData,
  updateResultRW,
} from "../../store/exams/actions";
import {
  fetchCompanies,
  fetchCompanyGrades,
} from "../../store/companies/actions";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { infoUser } from "../../store/users/actions";
import { getClasses } from "../../store/auth/actions";

import StudentEvaluationTable from "./shared/StudentAssessmentTable";
import SemanticFieldSelect from "./writing/SemanticFieldSelect";
import MonthSelect from "./shared/MonthSelect";
import GradesSelect from "./shared/GradesSelect";
import CompanySelect from "./shared/CompanySelect";
import FinishButton from "./shared/FinishButton";
import CSVExportButton from "./shared/CSVExportButton";
import GoogleForms from "../form/GoogleForms";
import { FORM_LINK } from "../../utils/constants";

const useStyles = makeStyles((theme) => ({
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  paddingCell: {
    "&.MuiTableCell-paddingNone": {
      padding: "8px 8px 4px 8px",
    },
  },
  select: {
    marginTop: "8px",
  },
}));

const Releases = ({
  fetchResultExam,
  area,
  infoUser,
  user,
  getClasses,
  fetchCompanyGrades,
  fetchReadAndWriting,
  fetchReadingAndWritingCSVData,
  fetchCompanies,
  companies,
  grades,
  userGrades,
  updateResultRW,
  resultRW,
  updateRW,
}) => {
  const [formLink, setFormLink] = useState("");
  const [isSemanticFieldSelected, setIsSemanticFieldSelected] = useState(false);
  const [semanticField, setSemanticField] = useState(undefined);
  const [month, setMonth] = useState(undefined);
  const [company, setCompany] = useState("");
  const [grade, setGrade] = useState("");
  const [studentsResult, setStudentsResult] = useState([]);
  const [sizeResult, setSizeResult] = useState(0);
  const [countResult, setCountResult] = useState({
    Reading: 0,
    Writing: 0,
  });

  const classes = useStyles();

  const writingFields = [
    "Presilabico",
    "SilabicoSemValorSonoro",
    "SilabicoComValorSonoro",
    "SilabicoAlfabetico",
    "Alfabetico",
    "NaoAvaliado",
    "AvaliacaoAdaptativa",
  ];

  const writingLabels = {
    Presilabico: "Pré-silábico",
    SilabicoSemValorSonoro: "Silábico SVS",
    SilabicoComValorSonoro: "Silábico CVS",
    SilabicoAlfabetico: "Silábico - Alfabético",
    Alfabetico: "Alfabético",
    NaoAvaliado: "Não avaliado",
    AvaliacaoAdaptativa: "Avaliação Adaptativa",
  };

  const ReadingFluencyFields = [
    "NaoLeitor",
    "Silaba",
    "Palavra",
    "Frase",
    "TextoSemFluencia",
    "TextoComFluencia",
    "NaoAvaliadoRF",
    "Prosodia",
    "Precisao",
    "Tempo",
    "Compreensao",
  ];

  const ReadingFluencyLabels = {
    NaoLeitor: "Não Leitor",
    Silaba: "Sílaba",
    Palavra: "Palavra",
    Frase: "Frase",
    TextoSemFluencia: "Texto sem fluência",
    TextoComFluencia: "Texto com fluência",
    NaoAvaliadoRF: "Não avaliado",
    AvaliacaoAdaptativa: "Avaliação Adaptativa",
    Prosodia: "Prosódia",
    Precisao: "Precisão",
    Tempo: "Tempo",
    Compreensao: "Compreensão",
  };

  const conditionalFields = [
    "Prosodia",
    "Precisao",
    "Tempo",
    "Compreensao",
    "CompreensaoDeLeitura",
    "AvaliacaoAdaptativa",
  ];

  const checkedFieldColors = {
    Presilabico: "#F13636",
    SilabicoSemValorSonoro: "#FF5C28",
    SilabicoComValorSonoro: "#F2DA00",
    SilabicoAlfabetico: "#5579F6",
    Alfabetico: "#32BD41",
    NaoAvaliado: "#302929",
    NaoAvaliadoRF: "#302929",
    NaoLeitor: "#B7B7B7",
    Silaba: "#F13636",
    Palavra: "#FF5C28",
    Frase: "#F2DA00",
    TextoSemFluencia: "#5570F6",
    TextoComFluencia: "#32BD41",
  };

  useEffect(() => {
    infoUser();
    getClasses();
    fetchReadingAndWritingCSVData();
  }, []);

  useEffect(() => {
    if (user && user.Role) {
      if (!["Super", "Guest", "District"].includes(user.Role)) {
        setCompany(user.CompanyId);
        fetchCompanyGrades(user.CompanyId);
      }
      if (["Super", "SuperUser", "District"].includes(user.Role))
        fetchCompanies("", 1);

      if (user.Role === "Master")
        setFormLink(FORM_LINK);
      else if (user.Role === "Teacher")
        setFormLink(FORM_LINK);
    }
  }, [user]);

  useEffect(() => {
    if (month && resultRW && resultRW[month]) {
      const students = Object.entries(resultRW[month])
        .map(([key, value]) => ({
          StudentId: key,
          ...value,
        }))
        .sort((a, b) => a.Name.localeCompare(b.Name));

      setSizeAndCount(students);
      setStudentsResult(students);
    } else {
      setStudentsResult([]);
    }
  }, [month, resultRW, updateRW]);

  const setResultRW = (month, studentId, modifiedData) => {
    let newRw = resultRW;
    newRw[month][studentId]["SemanticField"] = modifiedData["SemanticField"];
    newRw[month][studentId]["Observation"] = modifiedData["Observation"];
    newRw[month][studentId]["Reading"] = modifiedData["Reading"];
    newRw[month][studentId]["Writing"] = modifiedData["Writing"];

    return newRw;
  };

  const setSizeAndCount = (studentsList) => {
    const size = studentsList.length;
    const count = {
      Reading: {},
      Writing: {},
    };

    writingFields.map((f) => {
      count.Writing[f] = 0;
    });

    ReadingFluencyFields.map((f) => {
      count.Reading[f] = 0;
    });

    for (let i = 0; i < studentsList.length; i++) {
      writingFields.map((f) => {
        count.Writing[f] += studentsList[i].Writing[f] ? 1 : 0;
      });

      ReadingFluencyFields.map((f) => {
        count.Reading[f] += studentsList[i].Reading[f] ? 1 : 0;
      });
    }
    setSizeResult(size);
    setCountResult(count);
  };

  const handleGrade = (e) => {
    setGrade(e.target.value);
    fetchReadAndWriting(e.target.value, company);
    setIsSemanticFieldSelected(false);
  };

  const handleCompany = (e) => {
    setCompany(e.target.value);
    setGrade("");
    setMonth(undefined);
    setStudentsResult([]);
    fetchCompanyGrades(e.target.value);
  };

  const handleMonth = (e) => {
    setMonth(e.target.value);
    setIsSemanticFieldSelected(false);
  };

  return (
    <>
    {area === "Reading" || area === "Writing" ? (
      <>
        { companies ?
          (<CSVExportButton companies={companies} />) : null
        }
        {user && ["Super", "District"].includes(user.Role) ? (
          <CompanySelect
            company={company}
            companies={companies}
            handleCompany={handleCompany}
            style={classes.select}
          />
        ) : null}
        <GradesSelect
          grade={grade}
          grades={grades}
          user={user}
          userGrades={userGrades}
          handleGrade={handleGrade}
          style={classes.select}
        />
        {grade && (
          <MonthSelect
            month={month}
            handleMonth={handleMonth}
            style={classes.select}
          />
        )}
        {grade && month && area === "Writing" ? (
          <SemanticFieldSelect
            setOverallSemanticField={setSemanticField}
            studentsResult={studentsResult}
            month={month}
            setResultRW={setResultRW}
            setIsSemanticFieldSelected={setIsSemanticFieldSelected}
            style={classes.select}
          />
        ) : null}

        <StudentEvaluationTable
          grade={grade}
          month={month}
          semanticField={semanticField}
          area={area}
          studentsResult={studentsResult}
          setStudentsResult={setStudentsResult}
          setSizeAndCount={setSizeAndCount}
          setResultRW={setResultRW}
          sizeResult={sizeResult}
          countResult={countResult}
          writingFields={writingFields}
          writingLabels={writingLabels}
          ReadingFluencyFields={ReadingFluencyFields}
          ReadingFluencyLabels={ReadingFluencyLabels}
          conditionalFields={conditionalFields}
          checkedFieldColors={checkedFieldColors}
        />
        {grade && month && (
          <FinishButton
            area={area}
            studentsResult={studentsResult}
            isSemanticFieldSelected={isSemanticFieldSelected}
            conditionalFields={conditionalFields}
          />
        )}
      </>
    ) : (
      <Box sx={{height:"100%"}}>
        <GoogleForms link={formLink} />
      </Box>     
    )}
      
    </>
  );
};

Releases.propTypes = {
  fetchResultExam: PropTypes.func.isRequired,
  infoUser: PropTypes.func.isRequired,
  fetchReadAndWriting: PropTypes.func.isRequired,
  fetchReadingAndWritingCSVData: PropTypes.func.isRequired,
  user: PropTypes.object,
  fetchCompanies: PropTypes.func.isRequired,
  companies: PropTypes.object,
  fetchCompanyGrades: PropTypes.func.isRequired,
  updateResultRW: PropTypes.func.isRequired,
};

const mapStateToProps = ({ exams, users, auth, companies }) => ({
  user: users.infoUser,
  companies: companies.companiesList.companies,
  grades: companies.grades,
  userGrades: auth.classes,
  resultRW: exams.resultRW,
  updateRW: exams.updateRW,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchResultExam,
      infoUser,
      getClasses,
      fetchReadAndWriting,
      fetchReadingAndWritingCSVData,
      fetchCompanies,
      fetchCompanyGrades,
      updateResultRW,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Releases);
