/* eslint-disable */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import renderHTML from 'react-render-html'
import history from "../routes/history";

import {
  fetchExams,
  setExamId,
  searchExamTag,
  setCanEdit,
  clearExamModule,
  downloadExam,
} from "../store/exams/actions";
import * as services from "../store/exams/services";
import { infoUser } from "../store/users/actions";
import { logout } from "../store/auth/actions";

import ActionList from "../components/list/ActionList";
import ContainerCard from "../components/card/ContainerCard";
// import { PDFDownloadLink } from '@react-pdf/renderer'
// import { Checkbox } from 'antd'
import { List, Tooltip, Button, Tag, Spin } from "antd";
// import Exam from '../components/download/Exam'
// import QuestionsModal from '../components/modal/QuestionsModal'
import {
  Row,
  Col,
  Header,
  Empty,
  Pagination,
  StyledListItem,
} from "./styles/ListExamsPage.styles";
import { StyledButton } from "../components/modal/styles/Modal.styles";

import { Page } from "./styles/Page.styles";
import { Content } from "./styles/DetailsPage.styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import ConfigModal from "../components/modal/ConfigModal";
import TextField from '@mui/material/TextField';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";
import MuiButton from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Grid from '@mui/material/Grid';

// import useWindowSize from '../utils/useWindowSize'
// import http2 from '../utils/http2'

const useStyles = makeStyles((theme) => ({
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  inputField: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "50%",
    },
    border: "solid 2px #514FFF",
    borderRadius: "20px",
    padding: "5px 0 5px 15px",
    marginBottom: "15px",
  },
  examStatusButton: {
    border: "solid 1px #514FFF",
    borderEndEndRadius: "20px",
    borderEndStartRadius: "20px",
    borderTopRightRadius: "20px",
    borderTopLeftRadius: "20px",
    height: "40px",
    variant: "contained"
  }
}));

function ListExamsPage({
  fetchExams,
  exams,
  total,
  loading,
  setExamId,
  setCanEdit,
  searchExamTag,
  clearExamModule,
  infoUser,
  user,
  downloadExam,
  logout
}) {
  let [search, setSearch] = useState("");
  let [current, setCurrent] = useState(1);
  let [list, setList] = useState([]);
  let [isSimulieDatabase, setIsSimulieDatabase] = useState(false);
  let [listTotal, setListTotal] = useState(undefined);
  let [load, setLoad] = useState(undefined);
  let [questionsCompany, setQuestionsCompany] = useState(0);
  let [open, setOpen] = useState(false);
  let [openInformationDialog, setOpenInformationDialog] = useState(false);
  let [idExam, setIdExam] = useState("");
  let [exam, setExam] = useState("");
  let [tagsExam, setTagsExam] = useState([]);
  let [temGabarito, setTemGabarito] = useState(false);
  // let [embaralhar, setEmbaralhar] = useState(false)
  let [configModalView, setConfigModalView] = useState(false);
  let [questions, setQuestions] = useState(null);
  // let [document, setDocument] = useState(undefined)
  let [loadingPDF, setLoadingPDF] = useState(false);
  let [loadingDiagramar, setLoadingDiagramar] = useState(false);
  let [blockGerarLink, setBlockGerarLink] = useState(false);

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isHeaderOpen = Boolean(anchorEl);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMobileHeaderOpen = Boolean(mobileMoreAnchorEl);
  const [examStatus, setExamStatus] = useState("active");
  // const windowSize = useWindowSize()

  const handleMobileHeaderClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleQuestionHeaderClose = () => {
    setAnchorEl(null);
    handleMobileHeaderClose();
  };

  useEffect(() => {
    infoUser();
    clearExamModule();
  }, []);

  useEffect(() => {
    if (user !== null && user !== undefined) {
      let companyId;
      let userId;
      if (user.Role !== "SuperUser") {
        companyId = user.CompanyId;
      }
      // Se for super admin, nesta tela, lista todas as provas
      // Se for master ou docente, lista as provas da escola
      fetchExams("", 1, undefined, 10, userId, companyId, false);
      // setTimeout(function () {
      //   console.log('asdasd')
      // }, 500)
    }
  }, [user]);

  useEffect(() => {
    async function fetchCreateLink() {
      if (
        history.location.state &&
        history.location.state.configModalView.examId
      ) {
        await handleCreateLinkAction(
          history.location.state.configModalView.examId
        );
      }
    }
    fetchCreateLink();
  }, []);

  useEffect(() => {
    if (user !== null && user !== undefined) {
      let companyId;
      let userId;
      if (user.Role !== "SuperUser") {
        companyId = user.CompanyId;
      }
      fetchExams("", current, undefined, 10, userId, companyId, false,"Todas", "", examStatus);
    }
  },[examStatus])

  useEffect(() => {
    if (user && user.Role === 'Student') {
      logout();
    }
  }, [user]);

  function handleViewAction(examId) {
    setExamId(examId, false);
    history.push("/list-exams/list-questions-view?examId=" + examId);
  }

  function handleGabaritoAction(examId) {
    console.log(examId);
    setExamId(examId, false);
    // history.push('/list-questions?examId=' + examId)
  }

  async function handleDeleteAction(examId) {
    setLoad(true);
    await services.deleteExam(examId);
    let companyId;
    let userId;
    if (user !== null && user.Role !== "SuperUser") {
      companyId = user.CompanyId;
    }
    clearExamModule();
    let lExams = await services.fetchExams(
      search,
      current,
      search,
      10,
      userId,
      companyId
    );
    setList(lExams.Itens);
    setListTotal(lExams.TotalItens);
    setLoad(undefined);
    window.location.reload();
  }

  function handleStatusSearchButton(status){
    setExamStatus(status)
  }

  function handleEditAction(examId) {
    setExamId(examId, true);
    setCanEdit(true);
    history.push("/list-exams/list-questions?examId=" + examId);
  }

  // function setLoadingPDF (loadState) {
  //   setOpenPDF(loadState)
  // }

  function handleDownloadAction(exam) {
    // setOpenPDF(false)
    console.log(exam);
    setExam(exam);
    setTemGabarito(exam.IsResolutionFilled);
    setIdExam(exam.Id);
    setOpen(true);
  }

  async function handleInformationsAction(examId) {
    setIdExam(examId, true);
    const exam = (await services.fetchSelected([examId]))[0];
    console.log(exam);
    setExam(exam);
    setOpenInformationDialog(true);
  }

  async function handleCreateLinkAction(examId) {
    const exam = (await services.fetchSelected([examId]))[0];
    let qtdMultipleChoice = 0;
    setExamId(examId);

    if (
      exam.Questions.filter((el) => el.Type !== "Dissertative").length === 0
    ) {
      alert(
        "Não é possível gerar link de prova só com questões dissertativas. Selecione uma prova com questões de múltipla escolha."
      );
      setLoad(undefined);
      return null;
    }

    if (exam.Questions.filter((el) => el.Type === "Dissertative").length > 0) {
      alert(
        "Essa prova possui questões dissertativas. A prova online será criada apenas com as questões de múltipla escolha!"
      );
      setLoad(undefined);
    }

    const onlineQuestions = exam.Questions.filter((question) => {
      if (
        (question.Type === "Multiple Choice" ||
          question.Type === "MultipleChoice") &&
        question.Alternatives.filter((el) => el.Correct === true).length > 0
      ) {
        qtdMultipleChoice = qtdMultipleChoice + 1;
      }
      if (
        question.Type === "Multiple Choice" ||
        question.Type === "MultipleChoice"
      )
        return question;
      return question;
    });

    // if (qtdMultipleChoice !== onlineQuestions.length) {
    //   alert('Preencha o gabarito de todas as questões selecionadas!')
    //   setLoading(undefined)
    //   return false
    // }

    // Abrir Modal
    handleConfigModal(true);
    setQuestions(onlineQuestions);
    setBlockGerarLink((exam.IsSimulieDatabase === true && user.Role !== "SuperUser") || (exam.IsSuperDatabase === true && user.Role !== "Super" && user.Role !== "SuperUser"));
    setTagsExam(exam.Tags);
    setIsSimulieDatabase(exam.IsSimulieDatabase || exam.IsSuperDatabase);
  }

  function handleConfigModal(e) {
    setLoad(true);
    setConfigModalView(e);
    if (
      history.location.state &&
      history.location.state.configModalView.examId
    ) {
      history.push("/list-exams", { configModalView: { examId: false } });
    }
    if (e === false) {
      let userId;
      let companyId;
      fetchExams("", current, undefined, 10, userId, companyId, false);
    }
    setLoad(undefined);
  }

  function handleDownloadResolutionAction(examId) {
    const host = "https://api.simulie.com";
    const fileName = examId;
    const link = document.createElement("a");
    link.href = `${host}/exam/download/resolution-with-answers/${examId}`;
    link.target = "_blank";
    link.download = fileName;
    document.body.append(link);
    link.click();
    link.remove();
  }

  async function handleDownloadExamAction(examId, format) {
    setLoadingPDF(true);
    const exam = (await services.fetchSelected([examId]))[0];
    await services.downloadExam(exam, format);
    setLoadingPDF(false);
  }

  async function handleDiagramExamAction(examId) {
    setLoadingDiagramar(true);
    const exam = (await services.fetchSelected([examId]))[0];
    const userId = user.Id;
    const name = user.Name;
    const email = user.Email;
    const companyId = user.CompanyId;

    let authToken = user.AuthToken;
    const response = await services.diagramExam(
      exam,
      companyId,
      userId,
      authToken,
      name,
      email
    );

    if (!authToken) authToken = response.auth_token

    setLoadingDiagramar(false);
    const params = {
      id: response.data.id,
      type: response.data.type,
      file_url: response.data.attributes.file_url,
      name: `${response.data.attributes.name}.docx`,
      original_url: response.data.attributes.original_url,
      user_id: response.data.relationships.user.data.id,
      user_type: response.data.relationships.user.data.type,
    };

    window.open(`https://www.simulie.net/upload?id=${params.id}&type=${params.type}&file_url=${params.file_url}&name=${params.name}&original_url=${params.original_url}&user_id=${params.user_id}&user_type=${params.user_type}&auth_token=${authToken}`, '_blank').focus();
  }

  function handleDownloadResolutionCompleteAction(examId) {
    console.log(examId);
    const host = "https://api.simulie.com";
    const fileName = examId;
    const link = document.createElement("a");
    link.href = `${host}/exam/download/resolution-without-answers/${examId}`;
    link.target = "_blank";
    link.download = fileName;
    document.body.append(link);
    link.click();
    link.remove();
  }

  const searchExams = (search) => {
    setSearch(search)
    setListTotal(undefined);
    setList([]);
    let companyId;
    let userId;
    if (user !== null && user !== undefined) {
      if (user.Role !== "SuperUser") {
        companyId = user.CompanyId;
      }
      fetchExams(search, 1, search, 10, userId, companyId, false, "Todas", "", examStatus);
      if (search === "") {
        setSearch("");
      }
    }
  }
  const renderSearchField = (
    <TextField
      key="search"
      variant="standard"
      className={classes.inputField}
      InputProps={{ 
        disableUnderline: true,
        endAdornment: (
          <InputAdornment position="start">
            <SearchIcon style={{ color: "#514FFF"}}/>
          </InputAdornment>
          )
      }}
      placeholder="Buscar Provas" 
      onChange={(e) => searchExams(e.target.value)}
    />
  )

  const renderStatusSearchButton = (
    <ButtonGroup color="primary" aria-label="outlined primary button group">
        <MuiButton 
          className={classes.examStatusButton} 
          onClick={() => handleStatusSearchButton("draft")}
          variant={examStatus === "draft" ?  'contained' : 'outlined'}
        >
          Rascunho
        </MuiButton>
        <MuiButton 
          className={classes.examStatusButton} 
          onClick={() => handleStatusSearchButton("active")}
          variant={examStatus === "active" ?  'contained' : 'outlined'}
        >
          Aprovadas
        </MuiButton>
    </ButtonGroup>
  )

  const header = (
    <Row
      className={classes.sectionDesktop}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isHeaderOpen}
      onClose={handleQuestionHeaderClose}
    >
      <Col size={5}>
        <Header>TÍTULO</Header>
      </Col>
      <Col size={2}>
        <Header>DATA</Header>
      </Col>
      {/* <Col size={3}><Header>TAGS</Header></Col> */}
      {/* <Col size={3}><Header>GABARITO COMPLETO</Header></Col> */}
      <Col size={3}>
        <Header>AÇÕES</Header>
      </Col>
    </Row>
  );

  const mobileHeader = (
    <Row
      className={classes.sectionMobile}
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileHeaderOpen}
      onClose={handleMobileHeaderClose}
      mode="horizontal"
    >
      <Col size={6}>
        <Header>TÍTULO</Header>
      </Col>
      <Col size={2} style={{ display: "flex", justifyContent: "center" }}>
        <Header>AÇÕES</Header>
      </Col>
    </Row>
  );

  const renderName = (name) => (
    <Tooltip title={name} placement="topLeft">
      {name}
    </Tooltip>
  );

  const renderDate = (date) => {
    const localeDate = new Date(date).toLocaleDateString();
    return (
      <Tooltip title={localeDate} placement="topLeft">
        {localeDate}
      </Tooltip>
    );
  };

  const renderActions = (item, user, type) => {
    if (type === "mobile") {
      if (item.IsSimulieDatabase === true && user.Role !== "SuperUser") {
        return (
          <ActionList
            onInformation={() => handleInformationsAction(item.Id)}
            // onView={() => handleViewAction(item.Id)}
            // onGabarito={() => handleGabaritoAction(item)}
            // onDownload={() => handleDownloadAction(item)}
            // onGerarLink={() => handleCreateLinkAction(item.Id)}
          />
        );
      } else {
        return (
          <ActionList
            onInformation={() => handleInformationsAction(item.Id)}
            // onView={() => handleViewAction(item.Id)}
            // onEdit={() => handleEditAction(item.Id)}
            // onGabarito={() => handleGabaritoAction(item)}
            // onDownload={() => handleDownloadAction(item)}
            // onGerarLink={() => handleCreateLinkAction(item.Id)}
            // onDelete={() => handleDeleteAction(item.Id)}
          />
        );
      }
    } else {
      if ((item.IsSimulieDatabase === true && user.Role !== "SuperUser") || (item.IsSuperDatabase === true && (user.Role !== "Super" && user.Role !== "SuperUser"))) {
        return (
          <ActionList
            onView={() => handleViewAction(item.Id)}
            onGabarito={() => handleGabaritoAction(item)}
            onDownload={() => handleDownloadAction(item)}
            onGerarLink={() => handleCreateLinkAction(item.Id)}
            onDiagramar={() => handleDiagramExamAction(item.Id)}
            examStatus={examStatus}
          />
        );
      } else {
        return (
          <ActionList
            onView={() => handleViewAction(item.Id)}
            onEdit={() => handleEditAction(item.Id)}
            onGabarito={() => handleGabaritoAction(item)}
            onDownload={() => handleDownloadAction(item)}
            onGerarLink={() => handleCreateLinkAction(item.Id)}
            onDiagramar={() => handleDiagramExamAction(item.Id)}
            onDelete={() => handleDeleteAction(item.Id)}
            examStatus={examStatus}
          />
        );
      }
    }
  };

  const renderTags = (examId, tags) =>
    tags &&
    tags.map((tag, i) => {
      return (
        <Button key={i} type="primary" shape="round" className="tagButton">
          {tag}
        </Button>
      );
    });

  const renderGabarito = (item) => {
    if (item.IsResolutionFilled) {
      return (
        <Tag className="ant-btn-success ant-btn-round" color="#07e089">
          <span style={{ fontSize: "14px" }}>Sim</span>
        </Tag>
      );
    }
    return (
      <Tag className="ant-btn-danger ant-btn-round" color="#d65656">
        <span style={{ fontSize: "14px" }}>Não</span>
      </Tag>
    );
  };

  const renderItem = (item) => (
    <StyledListItem>
      <Row
        className={classes.sectionDesktop}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isHeaderOpen}
        onClose={handleQuestionHeaderClose}
      >
        <Col size={5}>{renderName(item.Title)}</Col>
        <Col size={2}>{renderDate(item.Created)}</Col>
        {/* <Col size={3}>{renderTags(item.Id, item.Tags)}</Col> */}
        {/* <Col size={3}>{renderGabarito(item)}</Col> */}
        <Col size={3}>{renderActions(item, user, "desktop")}</Col>
      </Row>
      <Row
        className={classes.sectionMobile}
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMobileHeaderOpen}
        onClose={handleMobileHeaderClose}
        mode="horizontal"
      >
        <Col size={6}>{renderName(item.Title)}</Col>
        <Col size={2} style={{ display: "flex", justifyContent: "center" }}>
          {renderActions(item, user, "mobile")}
        </Col>
      </Row>
    </StyledListItem>
  );

  const onChangePagination = (e) => {
    let companyId;
    let userId;
    if (user.Role !== "SuperUser") {
      companyId = user.CompanyId;
    }
    fetchExams(search , e, search, 10, userId, companyId, false, "Todas", "", examStatus);
    setListTotal(undefined);
    setList([]);
    setCurrent(e);
  };

  const pagination = (
    <Pagination
      current={current}
      total={listTotal !== undefined ? listTotal : total}
      onChange={onChangePagination}
    />
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseInformationDialog = () => {
    setOpenInformationDialog(false);
  };

  const renderInformationDialog = (
    <Dialog
      open={openInformationDialog}
      onClose={handleCloseInformationDialog}
      aria-labelledby="information-dialog-title"
      aria-describedby="information-dialog-description"
    >
      <DialogTitle id="information-dialog-title">
        <strong>{"Informações da Prova"}</strong>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="information-dialog-description">
          <Row>
            {(exam.IsSimulieDatabase === true && user.Role !== "SuperUser") || (exam.IsSuperDatabase === true && (user.Role !== "Super" || user.Role !== "SuperUser")) ? (
              <ActionList
                onView={() => handleViewAction(exam.Id)}
                onGabarito={() => handleGabaritoAction(exam)}
                onDownload={() => handleDownloadAction(exam)}
                onGerarLink={() => handleCreateLinkAction(exam.Id)}
                onDiagramar={() => handleDiagramExamAction(exam.Id)}
                examStatus={examStatus}
                />
            ) : (
              <ActionList
                onView={() => handleViewAction(exam.Id)}
                onEdit={() => handleEditAction(exam.Id)}
                onGabarito={() => handleGabaritoAction(exam)}
                onDownload={() => handleDownloadAction(exam)}
                onGerarLink={() => handleCreateLinkAction(exam.Id)}
                onDiagramar={() => handleDiagramExamAction(exam.Id)}
                onDelete={() => handleDeleteAction(exam.Id)}
                examStatus={examStatus}
              />
            )}
          </Row>
          <br />
          <Row>
            <div className={"col-sm-12"}>
              <label>
                <strong>Título da prova:</strong> {renderName(exam.Title)}
              </label>
            </div>
          </Row>
          <br />
          <Row>
            <div className={"col-sm-12"}>
              <label>
                <strong>Data de criação:</strong> {renderDate(exam.Created)}
              </label>
            </div>
          </Row>
          <br />
          {/* <Row>
            <div className={'col-sm-12'}>
              <label><strong>Tags:</strong> {renderTags(exam.Id, exam.Tags)}</label>
            </div>
          </Row> */}
          <br />
          <Row>
            <div className={"col-sm-12"}>
              <label>
                <strong>Gabarito Completo:</strong> {renderGabarito(exam)}
              </label>
            </div>
          </Row>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseInformationDialog} color="primary">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );

  const empty =
    load === undefined ? (
      <Empty
        description="Nenhum arquivo encontrado!"
        image={Empty.PRESENTED_IMAGE_DEFAULT}
      />
    ) : (
      <div>
        <Empty
          description="Carregando as Provas..."
          image={Empty.PRESENTED_IMAGE_DEFAULT}
        />
      </div>
    );

  // const onChangeEmbaralhar = e => {
  //   setEmbaralhar(e)
  // }

  // const MyDocument = () => (
  //   <Document>
  //     <ContainerCard marginTop={2} style={{ marginLeft: '0px', width: width, maxWidth: '976px' }} >
  //       renderHTML(correctImageSize(question.Statement))
  //     </ContainerCard>
  //   </Document>
  // )

  return (
    <Page>
      {/* <Spin spinning={load !== undefined ? load : false}> */}
      <Content style={{ width: "100%", maxWidth: "1200px" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {loadingDiagramar && <Spin spinning={loadingDiagramar} />}
        </div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Escolha um tipo"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {/* <Checkbox
                onChange={e => onChangeEmbaralhar(e.target.checked)}
                checked={embaralhar}
              >
                Embaralhar Questões?
              </Checkbox>
              <br />
              <br /> */}
              {/* <div style={openPDF ? {} : { display: 'none' }}>
                <PDFDownloadLink document={document} fileName='Prova.pdf'>
                  <Button type='primary' shape='round'> Prova </Button>
                </PDFDownloadLink>
              </div> */}
              {loadingPDF ? <Spin spinning={loadingPDF} /> : null}
              {loadingPDF ? <span>&nbsp;&nbsp;Carregando...</span> : null}
              {loadingPDF ? <br /> : null}
              {/* <br /> */}
              {/* <Button type='primary' shape='round' onClick={() => generatePDF(idExam)} className='tagButton'>TESTE</Button><br /><br /> */}
              {/* <StyledButton type='primary' shape='round' onClick={() => handleDownloadExamWithLayoutAction(idExam)} className='tagButton'>Prova com diagramação</StyledButton><br /><br /> */}
              {/* <StyledButton
                type="primary"
                shape="round"
                onClick={() => handleDownloadExamAction(idExam, "pdf")}
                className="tagButton"
              >
                Prova PDF
              </StyledButton>
              <br />
              <br /> */}
              <StyledButton
                type="primary"
                shape="round"
                onClick={() => handleDownloadExamAction(idExam, "docx")}
                className="tagButton"
              >
                Prova DOCX
              </StyledButton>
              <br />
              <br />
              <StyledButton
                type="primary"
                shape="round"
                onClick={() => handleDownloadResolutionCompleteAction(idExam)}
                className="tagButton"
              >
                Gabarito Não Preenchido
              </StyledButton>
              <br />
              <br />
              {temGabarito && (
                <StyledButton
                  type="primary"
                  shape="round"
                  onClick={() => handleDownloadResolutionAction(idExam)}
                  className="tagButton"
                >
                  Gabarito Preenchido
                </StyledButton>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <StyledButton onClick={handleClose} color="primary">
              Fechar
            </StyledButton>
          </DialogActions>
        </Dialog>
        {renderInformationDialog}
        <br />
        <br />
        <Grid container alignContent='center' justifyContent="space-between">
          {renderSearchField}
          {renderStatusSearchButton}
        </Grid>
        {header}
        {mobileHeader}
        <ContainerCard padding={0} shadowYOffset={5}>
          <List
            dataSource={list.length > 0 ? list : exams}
            locale={{ emptyText: empty }}
            renderItem={renderItem}
          />
        </ContainerCard>
        {configModalView && (
          <ConfigModal
            isSuperAdmin={["SuperUser", "Super"].includes(user.Role)}
            editView={false}
            id={idExam}
            onCancel={handleConfigModal}
            visible
            questions={questions}
            selected={idExam}
            examTags={tagsExam}
            isSimulieDatabase={isSimulieDatabase}
            userRole={user.Role}
            blockGerarLink={blockGerarLink}
          />
        )}
        {pagination}
      </Content>
      {/* </Spin> */}
    </Page>
  );
}

ListExamsPage.propTypes = {
  fetchExams: PropTypes.func.isRequired,
  downloadExam: PropTypes.func.isRequired,
  user: PropTypes.object,
  exams: PropTypes.array,
  loading: PropTypes.bool,
  total: PropTypes.number,
  setExamId: PropTypes.func.isRequired,
  setCanEdit: PropTypes.func.isRequired,
  clearExamModule: PropTypes.func,
  searchExamTag: PropTypes.func.isRequired,
  infoUser: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = ({ exams, questions, users }) => ({
  user: users.infoUser,
  exams: exams.examsList !== undefined ? exams.examsList.exams : [],
  total: exams.examsList !== undefined ? exams.examsList.total : 0,
  questionsCompany: questions.questionsCompany.length,
  loading: exams.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      infoUser,
      clearExamModule,
      fetchExams,
      downloadExam,
      setExamId,
      setCanEdit,
      searchExamTag,
      logout,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ListExamsPage);
