/* eslint-disable */
/** @jsx jsx */
import moment from "moment";
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchOutput } from "../../store/outputs/actions";
import { Modal, Spin, message } from "antd";
import React from "react";

import {
  QuestionsModalStyle,
  Label,
  StyledInput,
  StyledDatePicker,
  StyledTimePicker,
  StyledCheckbox,
  StyledButton,
  StyledSwitch,
  StyledTextArea,
} from "./styles/Modal.styles";
import { StyledTitle } from "../../pages/styles/ListExamsPage.styles";
import { Buttons } from "../../pages/styles/ReviseQuestionsPage.styles";
import { Row } from "../../pages/styles/Page.styles";

import {
  Select,
  Input,
  Chip,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";

import {
  downloadExam,
  setExamId,
  fetchOnlineExamInfo,
} from "../../store/exams/actions";
import {
  createExam,
  finishExam,
  updateExam,
  createOnlineExam,
  sendImages,
  downloadExamAnswerSheets,
} from "../../store/exams/services";
import { useEffect, useState } from "react";
// import Input from 'antd/es/input'
import SelectCompanies from "../input/SelectCompanies";
import SelectGradesToExam from "../input/SelectGradesToExam";
import ImageUploadButton from "../button/ImageUploadButton";
import LogoUploadButton from "../button/LogoUploadButton";
import SelectDistricts from "../input/SelectDistricts";
import SelectClasses from "../input/SelectClasses";
import SelectStudents from "../input/SelectStudents";
// import TextArea from 'antd/lib/input/TextArea'
import { FORTALEZA_TESTE_SUPER_ID } from "../../utils/constants";

const ConfigModal = ({
  onCancel,
  selected,
  questions,
  title,
  visible,
  examTags,
  fetchOnlineExamInfo,
  id,
  examInfo,
  editView,
  setExamId,
  isSuperAdmin,
  isSimulieDatabase,
  userRole,
  user,
  blockGerarLink = false,
}) => {
  const [description, setDescription] = useState(null);
  const [examTitle, setExamTitle] = useState(null);
  const [date, setDate] = useState({
    value: [null, null],
    dateString: [null, null],
  });
  const [blockEdition, setBlockEdition] = useState(false);
  const [gabDate, setGabDate] = useState(null);
  const [defaultGabDate, setDefaultGabDate] = useState(false);
  const [fastExam, setFastExam] = useState(false);
  const [videoExam, setVideoExam] = useState(false);
  const [videoBackgroundColor, setVideoBackgroundColor] = useState(null);
  const [removeAlternatives, setRemoveAlternatives] = useState(false);
  const [defaultShuffleQuestions, setDefaultShuffleQuestions] = useState(false);
  const [loading, setLoading] = useState(false);
  const [escolas, setEscolas] = useState([]);
  const [series, setSeries] = useState([]);
  const [grades, setGrades] = useState([]);
  const [allGrades, setAllGrades] = useState([]);
  const [time, setTime] = useState(null);
  const [link, setLink] = useState(null);
  const [linkCopied, setLinkCopied] = useState(null);
  const [endOpen, setEndOpen] = useState(false);
  const [videoQuestions, setVideoQuestions] = useState([]);
  const [videoTextColor, setVideoTextColor] = useState("#000000");
  const [disabledGerarLink, setDisabledGerarLink] = useState(false);
  const [templateFile, setTemplateFile] = useState(null);
  const [logoFile, setLogoFile] = useState(null);
  const [openLink, setOpenLink] = useState(null);
  const [enableOpenLink, setEnableOpenLink] = useState(false);
  const [districts, setDistricts] = useState([]);
  const [classes, setClasses] = useState([]);
  const [students, setStudents] = useState([]);
  const [clearStudents, setClearStudents] = useState(false);
  const [clearSchools, setClearSchools] = useState(false);

  useEffect(() => {
    if (id) fetchOnlineExamInfo(id);
  }, [id]);

  useEffect(() => {
    if (user && user.Role === "Master" || user.Role === "Teacher")
      setEscolas([user.CompanyId]);

    if (user && user.Role === "District")
      setDistricts([user.District]);
  }, [user]);

  useEffect(() => {
    if (examInfo && examInfo.length > 0) {
      // Bloqueia edição quando: user não é admin e questão é da database simulie
      setBlockEdition(!isSuperAdmin && isSimulieDatabase);

      const recentInfo = examInfo[0];

      if (recentInfo.Title) setExamTitle(recentInfo.Title);

      if (recentInfo.Instructions) setDescription(recentInfo.Instructions);

      if (recentInfo.ShuffleQuestions)
        setDefaultShuffleQuestions(recentInfo.ShuffleQuestions);

      if (recentInfo.StopAt === "0001-01-01T00:00:00Z") {
        setFastExam(true);
        return;
      }

      if (recentInfo.StartAt && recentInfo.StopAt)
        onChangeBothDate(recentInfo.StartAt, recentInfo.StopAt);
      else {
        if (recentInfo.StartAt)
          onChangeDate(
            "startValue",
            moment(recentInfo.StartAt),
            recentInfo.StartAt
          );

        if (recentInfo.StopAt) {
          onChangeDate(
            "endValue",
            moment(recentInfo.StopAt),
            recentInfo.StopAt
          );
        }
      }

      if (recentInfo.StopAt) {
        console.log(
          moment(recentInfo.StopAt),
          moment(),
          moment(recentInfo.StopAt) < moment()
        );

        if (moment(recentInfo.StopAt) < moment()) {
          setDisabledGerarLink(true);
        }
      }

      if (recentInfo.Duration) {
        const [hour, minute, second] = recentInfo.Duration.split(":");
        const duration = moment({ hour: hour, minute: minute, second: second });
        setTime(duration);
      }

      if (recentInfo.ResultReleaseTime) {
        handleUpdateGabDate(moment(recentInfo.StopAt), recentInfo.StopAt);
      } else {
        setDefaultGabDate(false);
      }

    }
  }, [examInfo]);

  const handleUpdateTime = (time) => {
    setTime(time);
  };

  function handleUpdateDescription(e) {
    setDescription(e.target.value);
  }

  function handleUpdateExamTitle(e) {
    setExamTitle(e.target.value);
  }

  function handleSelectVideoExam(e) {
    setVideoExam(e);
  }

  function handleUpdateGabDate(value, dateString) {
    setGabDate({
      value: value,
      dateString: dateString,
    });
  }

  function handleUpdateDefaultGabDate(e) {
    setDefaultGabDate(e.target.checked);
  }

  function handleRemoveAlternatives(e) {
    setRemoveAlternatives(e.target.checked);
  }

  function handleShuffleQuestions(e) {
    setDefaultShuffleQuestions(e.target.checked);
  }

  function handleSelectFastExam(e) {
    setFastExam(e);
  }

  function handleSelectOpenLink(e) {
    setEnableOpenLink(e);
  }

  function handleSelectCompany(value) {
    setEscolas(value);
  }

  function handleSelectDistricts(value) {
    setDistricts(value)
    setClearSchools(true)
    setClearStudents(true);
    setEscolas([])
    setStudents([]);
    setGrades([]);
  }

  function handleSelectClasses(value) {
    setGrades(value);
    setClearStudents(true);
    setStudents([]);
  }

  function handleClassesLoaded(classrooms) {
    setAllGrades(classrooms);
  }

  function handleSelectStudents(value) {
    setStudents(value);
  }

  function handleSeries(value) {
    setSeries(value.target.value);
  }

  const newExam = () => {
    let array = [...questions];
    let questionList = array.map((qs, index) => {
      let theAlternatives = [];
      if (qs.Alternatives !== undefined) {
        theAlternatives = qs.Alternatives.map((alter, index) => {
          return {
            Correct: alter.Correct,
            Order: alter.Order,
          };
        });
      }

      let status = "Active";

      return {
        Id: qs.Id,
        Number: index + 1,
        Statement: qs.Statement,
        Alternatives: theAlternatives,
        Type:
          qs.Type !== undefined ? qs.Type.replace(" ", "") : "MultipleChoice",
        Status: status,
        Tags: qs.Tags,
        Content: qs.Content,
        ParentContent: qs.ParentContent,
      };
    });

    let arrayQuestoes = [];

    arrayQuestoes = questionList;

    if (editView) {
      const query = new URLSearchParams(window.location.search);
      if (id === null) {
        let examIdAux = query.get("examId");
        console.log("id", examIdAux);
        return {
          Title: title || "Prova Criada Online",
          Questions: arrayQuestoes,
          Tags: examTags,
        };
      } else {
        return {
          Title: title || "Prova Criada Online",
          Questions: arrayQuestoes,
          Tags: examTags,
        };
      }
    } else {
      return {
        Title: title || "Prova Criada Online",
        Questions: arrayQuestoes,
        Tags: examTags,
      };
    }
  };

  const handleCopyLink = async () => {
    let text = "https://simulie.com/student/exam?examId=" + link;
    if (enableOpenLink) {
      text = "https://simulie.com/open-exam?examId=" + openLink;
    }

    await navigator.clipboard.writeText(text);
    setLinkCopied(true);
  };

  const handleAnswerSheets = async () => {
    await downloadExamAnswerSheets(link);
  }

  const handleCreateLink = async () => {
    setLinkCopied(null);
    if (user.Role !== "Super") {
      if (!escolas || escolas.length === 0) {
        alert("Selecione ao menos uma escola.");
        return false;
      }
    }
    if (!fastExam) {
      if (!date) {
        alert("Preencha o Período de Disponibilização");
        return false;
      }
      if (!gabDate && !defaultGabDate) {
        alert("Preencha a Data de Disponibilização do Gabarito");
        return false;
      }
      if (time) {
        if (
          time.hours() * 3600000 +
          time.minutes() * 60000 +
          time.seconds() * 1000 >
          Date.parse(date.value[1]._d) - Date.parse(date.value[0]._d)
        ) {
          alert(
            "O período de disponibilização da prova precisa ser maior que o tempo de prova"
          );
          return false;
        }
      }
    }

    let exam = newExam();
    let bok = true;
    if (isSuperAdmin) {
      if (escolas !== undefined && escolas.length > 0) {
        exam.Companies = escolas;
      } else {
        exam.Companies = [];
      }
    }

    if (grades !== undefined && grades.length > 0) {
      exam.Grades = grades;
    } else {
      exam.Grades = [];
    }

    if (exam.Questions.filter((el) => el.Type === "Dissertative").length > 0) {
      alert(
        "Essa prova possui questões dissertativas. A prova online será criada apenas com as questões de múltipla escolha!"
      );
      setLoading(false);
    }

    if (bok) {
      setLoading(true);
      let newId = id;
      let qtdMultipleChoice = 0;

      if (editView) {
        const query = new URLSearchParams(window.location.search);
        newId = query.get("examId");
        await updateExam(newId, exam);
        setExamId(newId);
      } else {
        newId = newId || (await createExam(exam));
        setExamId(newId);
        await finishExam(newId, exam);
      }

      let alternatives = true;

      // eslint-disable-next-line
      const onlineQuestions = exam.Questions.filter((question) => {
        if (alternatives) {
          if (question.Alternatives.filter((el) => el.Correct === true).length === 0) {
            if (question.Type !== "Dissertative" && question.Content.length === 0) {
              alternatives = false;
            }
          }
          if (
            (question.Type === "Multiple Choice" || question.Type === "MultipleChoice")
            && question.Content.length == 0
          ) {
            qtdMultipleChoice = qtdMultipleChoice + 1;
            return question;
          }
        }
      });
      if (!alternatives) {
        alert("Preencha o gabarito de todas as questões selecionadas!");
        setLoading(false);
        return false;
      }

      let templateUrl = null;
      if (templateFile) templateUrl = await sendImages(templateFile);

      let logoUrl = null;
      if (logoFile) logoUrl = await sendImages(logoFile);

      const finalGrades = grades.length === 0 ? allGrades : grades;

      const payload = {
        Configuration: {
          Id: id,
          Title: enableOpenLink
            ? "Open Test"
            : examTitle || "Prova Online Criada",
          Instructions: fastExam ? null : description,
          StartAt: fastExam ? null : date.value[0]._d.toISOString(),
          StopAt: fastExam ? null : date.value[1]._d.toISOString(),
          ResultReleaseTime: !fastExam
            ? defaultGabDate
              ? null
              : gabDate.value._d.toISOString()
            : null,
          ShuffleQuestions: defaultShuffleQuestions,
          RemoveAlternatives: removeAlternatives,
          // ShuffleQuestionsAlternatives: defaultShuffleQuestionsAlternatives,
          Duration: (fastExam || time === null) ? "999999:00:00" : time.hours().toString() +
            ":" +
            time.minutes().toString() +
            ":" +
            time.seconds().toString(),
          Grades: finalGrades,
          TemplateUrl: templateUrl,
          LogoUrl: logoUrl,
          VideoTextColor: videoTextColor,
          VideoBackgroundColor: videoBackgroundColor,
          IsOpenTest: enableOpenLink,
          Series: series,
          Companies: escolas,
          Districts: districts,
          Students: students,
        },
        Questions: onlineQuestions.map((q) => {
          q.IsVideo = videoQuestions.some((v) => v.Id === q.Id);
          return q;
        }),
      };

      console.log(payload);
      const res = await createOnlineExam(payload);
      if (enableOpenLink) {
        setOpenLink(res);
      } else {
        setLink(res);
      }
      setLoading(false);
    } else {
      alert("Selecione escola(s)");
      return false;
    }
  };

  const backButton = (
    <StyledButton
      htmlType="button"
      shape="round"
      onClick={() => onCancel(false)}
    >
      Voltar
    </StyledButton>
  );

  const disabledStartDate = (startValue) => {
    let endValue = date.value[1];
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };

  const disabledEndDate = (endValue) => {
    let startValue = date.value[0];
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };

  const onChangeBothDate = (startAt, stopAt) => {
    const dictValue = [moment(startAt), moment(stopAt)];
    const dictStringValue = [startAt, stopAt];

    setDate({
      value: dictValue,
      dateString: dictStringValue,
    });
  };

  const onChangeDate = (field, value, dateString) => {
    let dictValue;
    let dictStringValue;

    if (field === "startValue") {
      dictValue = [value, date.value[1]];
      dictStringValue = [dateString, date.dateString[1]];
    } else if (field === "endValue") {
      dictValue = [date.value[0], value];
      dictStringValue = [date.dateString[0], dateString];
    }

    setDate({
      value: dictValue,
      dateString: dictStringValue,
    });
  };

  const onStartChange = (value, dateString) => {
    onChangeDate("startValue", value, dateString);
  };

  const onEndChange = (value, dateString) => {
    onChangeDate("endValue", value, dateString);
  };

  const handleStartOpenChange = (open) => {
    if (!open) {
      setEndOpen(true);
    }
  };

  const handleEndOpenChange = (open) => {
    setEndOpen(open);
  };

  const handleVideoQuestionChange = (event) => {
    setVideoQuestions(event.target.value);
  };

  const handleVideoTextColor = (event) => {
    setVideoTextColor(event.target.value);
  };

  const handleVideoBackgroundColor = (event) => {
    setVideoBackgroundColor(event.target.value);
  };

  function beforeUpload(file) {
    const isJpgOrPngOrJpegOrGif =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg";
    if (!isJpgOrPngOrJpegOrGif) {
      message.error("Você só pode fazer upload de arquivos JPG/PNG/JPEG!");
    }
    const isLt2M = file.size / 1024 / 1024 < 1;
    if (!isLt2M) {
      message.error("A imagem deve ser menor que 1MB!");
    }

    return isJpgOrPngOrJpegOrGif && isLt2M;
  }

  const uploadTemplate = (event) => {
    const file = event.target.files[0];
    if (beforeUpload(file)) setTemplateFile(event.target.files[0]);
  };

  const uploadLogo = async (event) => {
    const file = event.target.files[0];
    if (beforeUpload(file)) setLogoFile(event.target.files[0]);
  };

  const openLinkOption = (role) => {
    const allowedRoles = ["Master", "SuperUser", "Retail"];
    return allowedRoles.includes(role);
  };

  return (
    <Modal
      visible={visible}
      onCancel={() => onCancel(false)}
      footer={backButton}
      css={QuestionsModalStyle}
      destroyOnClose={() => onCancel(false)}
    >
      <Spin spinning={loading}>
        <Row>
          <div className={"col-sm-12"}>
            <StyledSwitch
              onChange={handleSelectFastExam}
              checked={fastExam}
              disabled={blockEdition}
            />{" "}
            <span
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                fontFamily: '"Source Sans Pro", sans-serif',
                color: "#514fff",
              }}
            >
              Prova Rápida
            </span>
          </div>
        </Row>
        <Row>
          <div className={"col-sm-12"}>
            {openLinkOption(userRole) && (
              <>
                <StyledSwitch
                  onChange={handleSelectOpenLink}
                  checked={enableOpenLink}
                  disabled={blockEdition}
                />{" "}
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    fontFamily: '"Source Sans Pro", sans-serif',
                    color: "#514fff",
                  }}
                >
                  Prova Aberta
                </span>
              </>
            )}
          </div>
        </Row>
        <Row>
          <div className={"col-sm-12"}>
            <StyledSwitch
              onChange={handleSelectVideoExam}
              checked={videoExam}
              disabled={blockEdition}
            />{" "}
            <span
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                fontFamily: '"Source Sans Pro", sans-serif',
                color: "#514fff",
              }}
            >
              Prova em Vídeo
            </span>
          </div>
        </Row>
        {
          user &&
          (user.Id === FORTALEZA_TESTE_SUPER_ID) &&
          (
            <Row>
              <div className={"col-sm-12"}>
                <Label>Distritos:</Label>
                <SelectDistricts
                  onChange={handleSelectDistricts}
                  options={user.District ? [user.District] : []}
                />
              </div>
            </Row>
          )
        }
        {(isSuperAdmin || user.Role === "District") && (
          <Row>
            <div className={"col-sm-12"}>
              <Label>Escolas:</Label>
              <SelectCompanies
                disabled={blockEdition}
                onChange={handleSelectCompany}
                companies={selected.CompanyId}
                districts={districts}
                clearSchools={clearSchools}
                setClearSchools={setClearSchools}
              />
            </div>
          </Row>
        )}
        {(isSuperAdmin) && (
          <Row>
            <div className={"col-sm-12"}>
              <FormControl style={{ width: 300 }}>
                <Label>Séries:</Label>
                <Select
                  multiple
                  disabled={blockEdition}
                  input={<Input />}
                  onChange={handleSeries}
                  value={series}
                  renderValue={(selected) => (
                    <div>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </div>
                  )}
                >
                  {[
                    "1EF",
                    "2EF",
                    "3EF",
                    "4EF",
                    "5EF",
                    "6EF",
                    "7EF",
                    "8EF",
                    "9EF",
                    "1EM",
                    "2EM",
                    "3EM",
                    "ETAPA 1",
                    "ETAPA 2",
                    "ETAPA 3",
                    "ETAPA 4",
                    "ETAPA 5",
                    "ETAPA 6",
                  ].map((serie) => (
                    <MenuItem key={serie} value={serie}>
                      {serie}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </Row>
        )}
        {
          escolas.length > 0 && !enableOpenLink && (
            <Row>
              <div className={"col-sm-12"}>
                <Label>Turma(s):</Label>
                <SelectClasses
                  onChange={handleSelectClasses}
                  onLoaded={handleClassesLoaded}
                  schools={escolas}
                />
              </div>
            </Row>
          )
        }
        {
          grades.length === 1 && !enableOpenLink && (
            <Row>
              <div className={"col-sm-12"}>
                <Label>Alunos:</Label>
                <SelectStudents
                  onChange={handleSelectStudents}
                  schools={escolas}
                  classes={grades}
                  clearStudents={clearStudents}
                  setClearStudents={setClearStudents}
                />
              </div>
            </Row>
          )
        }
        <Row>
          <div className={"col-sm-12"}>
            <Label>Título:</Label>
            <StyledInput
              disabled={blockEdition}
              value={
                examTitle != null
                  ? examTitle
                  : selected !== undefined &&
                    selected !== null &&
                    selected.Title !== ""
                    ? selected.Title
                    : ""
              }
              onInput={handleUpdateExamTitle}
            />
          </div>
        </Row>
        {!fastExam && (
          <Row>
            <div className={"col-sm-12"}>
              <Label>Instruções para a prova:</Label>
              <StyledTextArea
                disabled={blockEdition}
                autoSize={{ minRows: 2, maxRows: 6 }}
                value={
                  description != null
                    ? description
                    : selected !== undefined &&
                      selected !== null &&
                      selected.Description !== ""
                      ? selected.Description
                      : ""
                }
                onInput={handleUpdateDescription}
              />
            </div>
          </Row>
        )}
        {!fastExam && (
          <Row>
            <div className={"col-sm-12"}>
              <Label>*Período de disponibilização da prova:</Label>
              <div>
                <StyledDatePicker
                  disabled={blockEdition}
                  disabledDate={disabledStartDate}
                  showTime
                  format="YYYY-MM-DD HH:mm:ss"
                  value={date.value[0]}
                  placeholder="Início"
                  onChange={onStartChange}
                  onOpenChange={handleStartOpenChange}
                />
                <StyledDatePicker
                  disabled={blockEdition}
                  disabledDate={disabledEndDate}
                  showTime
                  format="YYYY-MM-DD HH:mm:ss"
                  value={date.value[1]}
                  placeholder="Fim"
                  onChange={onEndChange}
                  open={endOpen}
                  onOpenChange={handleEndOpenChange}
                  style={{ marginLeft: "8px" }}
                />
              </div>
            </div>
          </Row>
        )}
        {!fastExam && (
          <Row>
            <div className={"col-sm-12"}>
              <Label>Duração da prova:</Label>
              <StyledTimePicker
                disabled={blockEdition}
                value={time}
                onChange={handleUpdateTime}
                placeholder={"Duração"}
              />
            </div>
          </Row>
        )}
        {!defaultGabDate && !fastExam ? (
          <Row>
            <div className={"col-sm-12"}>
              <Label>*Data de disponibilização do gabarito:</Label>
              <br />
              <StyledDatePicker
                disabled={blockEdition}
                showTime={{ format: "HH:mm" }}
                format="YYYY-MM-DD HH:mm"
                onChange={handleUpdateGabDate}
                value={gabDate ? gabDate.value : null}
              />
            </div>
          </Row>
        ) : (
          ""
        )}
        {!fastExam && (
          <Row>
            <div className={"col-sm-12"}>
              <StyledCheckbox
                onChange={handleUpdateDefaultGabDate}
                disabled={blockEdition}
              >
                <StyledTitle>
                  Disponibilizar gabarito quando o aluno finalizar a prova
                </StyledTitle>
              </StyledCheckbox>
              <br />
            </div>
          </Row>
        )}
        {
          <Row>
            <div className={"col-sm-12"}>
              <StyledCheckbox
                onChange={handleRemoveAlternatives}
                disabled={blockEdition}
              >
                Remover as letras das alternativas da prova
              </StyledCheckbox>
              <br />
            </div>
          </Row>
        }
        {
          <Row>
            <div className={"col-sm-12"}>
              <StyledCheckbox
                onChange={handleShuffleQuestions}
                disabled={blockEdition}
              >
                Embaralhar questões da prova
              </StyledCheckbox>
              <br />
            </div>
          </Row>
        }
        <Row>
          <div className={"col-sm-12"}>
            <Label>Carregar Logo - JPG/JPEG/PNG até 1MB</Label>
            <LogoUploadButton
              disabled={blockEdition}
              buttonText="Carregar"
              onInputChange={uploadLogo}
              file={logoFile}
            />
          </div>
        </Row>
        {videoExam && questions && questions.length > 0 && (
          <div>
            <Row>
              <div className={"col-sm-12"}>
                <FormControl style={{ width: 300 }}>
                  <Label>Questões em Vídeo</Label>
                  <Select
                    multiple
                    disabled={blockEdition}
                    value={videoQuestions}
                    onChange={handleVideoQuestionChange}
                    input={<Input />}
                    renderValue={(selected) => (
                      <div>
                        {selected.map((value) => (
                          <Chip key={value.Id} label={value.Number} />
                        ))}
                      </div>
                    )}
                  >
                    {questions.map((q) => (
                      <MenuItem key={q.Id} value={q}>
                        {q.Number}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </Row>
            <Row>
              <div className={"col-sm-12"}>
                <FormControl style={{ width: 300 }}>
                  <Label>Cores</Label>
                  <Select
                    disabled={blockEdition}
                    onChange={handleVideoBackgroundColor}
                    value={videoBackgroundColor}
                  >
                    <MenuItem key={"blue"} value={"blue"}>
                      Azul
                    </MenuItem>
                    <MenuItem key={"white"} value={"white"}>
                      Branco
                    </MenuItem>
                    <MenuItem key={"orange"} value={"orange"}>
                      Laranja
                    </MenuItem>
                    <MenuItem key={"green"} value={"green"}>
                      Verde
                    </MenuItem>
                    <MenuItem key={"red"} value={"red"}>
                      Vermelho
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </Row>
            <Row>
              <div className={"col-sm-12"}>
                <FormControl style={{ width: 300 }}>
                  <Label>Cor do Texto do Vídeo</Label>
                  <Select
                    disabled={blockEdition}
                    onChange={handleVideoTextColor}
                    value={videoTextColor}
                  >
                    <MenuItem key={"#000000"} value={"#000000"}>
                      Preto
                    </MenuItem>
                    <MenuItem key={"#FFFFFF"} value={"#FFFFFF"}>
                      Branco
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </Row>
            <Row>
              <div className={"col-sm-12"}>
                <Label>Carregar Template - JPG/JPEG/PNG até 1MB</Label>
                <ImageUploadButton
                  disabled={blockEdition}
                  buttonText="Carregar"
                  onInputChange={uploadTemplate}
                  file={templateFile}
                />
              </div>
            </Row>
          </div>
        )}
        {!fastExam && (
          <Row>
            <div className={"col-sm-12"}>
              <br />
              <br />
              <Label>*De acordo com o horário de Brasília</Label>
              <br />
              <br />
            </div>
          </Row>
        )}
        {enableOpenLink ? (
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Label>Link Aberto Gerado:</Label>
            {openLink && "https://simulie.com/open-exam?examId=" + openLink}
          </Row>
        ) : (
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Label>Link Gerado:</Label>
            {link && "https://simulie.com/student/exam?examId=" + link}
          </Row>
        )}
        <Row>
          <Buttons>
            <StyledButton
              type="primary"
              onClick={() => handleCreateLink()}
              shape="round"
              disabled={(!fastExam && disabledGerarLink) || blockGerarLink}
            >
              Gerar Link
            </StyledButton>
            {link && (
              <>
              <StyledButton
                type="primary"
                onClick={() => handleCopyLink()}
                shape="round"
              >
                Copiar Link
              </StyledButton>
              <StyledButton
                type="primary"
                onClick={() => handleAnswerSheets()}
                shape="round"
              >
                Cartões Resposta
              </StyledButton>
            </>
            )}
          </Buttons>
        </Row>
        {linkCopied && (
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <StyledTitle>Link Copiado!</StyledTitle>
          </Row>
        )}
      </Spin>
    </Modal>
  );
};

ConfigModal.propTypes = {
  id: PropTypes.string,
  selected: PropTypes.any,
  editView: PropTypes.bool,
  listPage: PropTypes.bool,
  downloadFile: PropTypes.string,
  questions: PropTypes.array,
  title: PropTypes.string,
  inactiveQuestions: PropTypes.array,
  realQuestions: PropTypes.array,
  examTags: PropTypes.array,
  fetchOnlineExamInfo: PropTypes.func,
  setExamId: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  isSuperAdmin: PropTypes.bool.isRequired,
  userRole: PropTypes.string.isRequired,
  user: PropTypes.object
};

const mapStateToProps = ({ outputs, exams, auth }) => ({
  hideWarnings: outputs.hideWarnings,
  loading: outputs.loading,
  downloadFile: exams.downloadFile,
  id: exams.id,
  fetchOnlineExamInfo: exams.fetchOnlineExamInfo,
  examInfo: exams.examInfo,
  user: auth.loggedUser, 
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchOutput,
      setExamId,
      finishExam,
      downloadExam,
      fetchOnlineExamInfo,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ConfigModal);
