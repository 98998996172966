import http, { updateToken } from '../../utils/http2'

const companyEndpoint = '/company/'
const deleteCompanyEndpoint = '/company/'
const editCompanyEndpoint = '/company/'
const fetchCompanyEndpoint = '/basic'
const searchCompanyEndpoint = companyEndpoint + 'search/paged'
const imageRateLimitEndpoint = companyEndpoint + 'can-generate-images'
const aiLimitCountEndpoint = companyEndpoint + 'get-remaining-image-count'

export const createCompany = company => {
  updateToken()
  return http
    .post(companyEndpoint, company)
    .then(res => res.data).catch(error => error.data)
}

export const fetchCompanies = (title, page, districts = null) => {
  updateToken()
  if (page === undefined || page === '') { page = 1 }
  let url = searchCompanyEndpoint + `?status=active&itensPerPage=1000&page=` + page
  if (title !== undefined && title !== '') {
    url += `&name=` + title
  }
  if (districts !== null && districts.length > 0) {
    url += `&districts=` + districts.join('&districts=')
  }
  return http
    .get(url)
    .then(res => res.data).catch(error => error.data)
}

export const fetchCompanyGrades = id => {
  updateToken()
  return http
    .get(companyEndpoint + id + "/grades")
    .then(res => res.data).catch(error => error.data)
}

export const fetchCompanyClasses = (companies) => {  
  updateToken();
  let classesRequest = '';
  if (Array.isArray(companies) && companies.length > 0) {
    classesRequest = companies
      .map(id => `companiesId[]=${encodeURIComponent(id)}`)
      .join('&');
  }
  const url = `${companyEndpoint}classes?${classesRequest}`;
  return http
    .get(url)
    .then(res => res.data)
    .catch(error => error.data);
}

export const fetchClassStudents = (companies, companyClass) => {
  updateToken()
  let studentsRequest = '?'
  if (Array.isArray(companies) && companies.length > 0) {
    studentsRequest = companies.map(id => `companiesId[]=${encodeURIComponent(id)}`).join('&');
  }
  const url = `${companyEndpoint}class/students?${studentsRequest}&companyClass=${companyClass}`;
  return http
    .get(url)
    .then(res => res.data)
    .catch(error => error.data)
}

export const fetchCompany = id => {
  updateToken()
  return http
    .get(companyEndpoint + id)
    .then(res => res.data).catch(error => error.data)
}

export const fetchCompanyBasic = id => {
  updateToken()
  return http
    .get(companyEndpoint + id + fetchCompanyEndpoint)
    .then(res => res.data).catch(error => error.data)
}

export const deleteCompany = id => {
  updateToken()
  return http
    .delete(deleteCompanyEndpoint + id)
    .then(res => res.data).catch(error => error.data)
}

export const editCompany = (id, company) => {
  updateToken()
  return http
    .put(editCompanyEndpoint + id, company)
    .then(res => res.data).catch(error => error.data)
}

export const checkImageGenerationLimit = (companyId, numQuestions) => {
  updateToken()
  return http
  .post(imageRateLimitEndpoint, {
    companyId: companyId,
    numQuestions: numQuestions
  })
  .then(res => res.data).catch(error => error.data)  
}

export const getAILimitCount = (companyId) => {
  updateToken();
  return http
    .get(aiLimitCountEndpoint, { params: { companyId: companyId } })
    .then(res => res.data)
    .catch(error => error.data);  
};
