/* eslint-disable */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchOutput } from "../../store/outputs/actions";
import { infoUser } from "../../store/users/actions";
import { Modal, Spin, Select } from "antd";
import {
  QuestionsModalStyle,
  StyledButton,
  StyledInput,
  QuestionCardContainer,
  QuestionTitle,
  QuestionStatement,
  RemoveButton,
  StyledSelect,
} from "./styles/Modal.styles";
import { Grid, useTheme, useMediaQuery } from '@mui/material';
import { Buttons } from "../../pages/styles/ReviseQuestionsPage.styles";
import { Row } from "../../pages/styles/Page.styles";
import { StyledTitle } from "../../pages/styles/ListExamsPage.styles";
import { StyledCheckbox } from "../question/QuestionCard/styles";

import { downloadExam, setExamId } from "../../store/exams/actions";
import { createExam, finishExam, updateExam } from "../../store/exams/services";
import { fetchCompanies } from "../../store/companies/services";
import React,{ useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
const { Option } = Select;
import { NH_SUPER_ID } from "../../utils/constants";

import SelectCompanies from "../input/SelectCompanies";

const SaveModal = ({
  onCancel,
  selected,
  questions,
  visible,
  examTags,
  id,
  editView,
  setExamId,
  isSuperAdmin,
  inactiveQuestions,
  removeQuestion,
  removeAllQuestions,
  cartQuestions,
  user,
  infoUser,
}) => {
  const [title, setTitle] = useState(null);
  const [loading, setLoading] = useState(false);
  const [escolas, setEscolas] = useState([]);
  const [companies, setCompanies] = useState(undefined);
  const [loadCompanies, setLoadCompanies] = useState(true);
  const [embaralhar, setEmbaralhar] = useState(false);
  const [embaralharAlternatives, setEmbaralharAlternatives] = useState(false);
  const [subject, setSubject] = useState(null);
  const [selectedQuestions, setSelectedQuestions] = useState([]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  let subjects = [
    "Matemática",
    "Química",
    "Biologia",
    "História",
    "Física",
    "Geografia",
    "Filosofia",
    "Sociologia",
    "Língua Portuguesa",
    "Inglês",
    "Espanhol"
  ];
  useEffect(() => {
    if (isSuperAdmin && loadCompanies) {
      fetchCompanies("", 1).then((c) => {
        setCompanies(c);
        setEscolas(c.Itens.map((j) => j.Id));
        setLoadCompanies(false);
      });
    }
  });

  useEffect(()=>{
    if (user.Role === "Teacher" && user.Subject !== null)
      setSubject(user.Subject);
  },[user])

  useEffect(() => {
    if (cartQuestions.length > 0 && selectedQuestions.length === 0) {
      setSelectedQuestions(cartQuestions);
    }
    else if (cartQuestions.length > selectedQuestions.length) {
      let updatedQuestions = cartQuestions.filter((q) => !selectedQuestions.includes(q));
      let newSelectedQuestions = selectedQuestions.concat(updatedQuestions);
      setSelectedQuestions(newSelectedQuestions);
    }
    else if (cartQuestions.length < selectedQuestions.length) {
      let updatedQuestions = selectedQuestions.filter((q) => cartQuestions.includes(q));
      setSelectedQuestions(updatedQuestions);
    }
  }, [cartQuestions]);

  function handleUpdateTitle(e) {
    setTitle(e.target.value);
  }

  function handleUpdateEmbaralhar(e) {
    setEmbaralhar(e.target.checked);
  }

  function handleUpdateEmbaralharAlternatives(e) {
    setEmbaralharAlternatives(e.target.checked);
  }

  function handleSelectCompany(value) {
    if (value.includes("")) {
      setEscolas(companies.Itens.map((j) => j.Id));
    } else {
      setEscolas(value);
    }
  }

  function handleSubject(value) {
    setSubject(value);
  }

  function shuffle(myArray) {
    let arrayAux = myArray;
    for (let i = arrayAux.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * i);
      let temp = arrayAux[i];
      arrayAux[i] = arrayAux[j];
      arrayAux[j] = temp;
    }
    return arrayAux;
  }

  const newExam = () => {
    let array = [...selectedQuestions];
    let questionList = array.map((qs, index) => {
      let theAlternatives = [];
      if (qs.Alternatives !== undefined) {
        theAlternatives = qs.Alternatives.map((alter, index) => {
          return {
            Correct: alter.Correct,
            Description: alter.Description,
            Order: alter.Order,
          };
        });
      }

      let exists = inactiveQuestions.find((e) => e.Id === qs.Id);
      let status = "Active";
      if (exists !== null && exists !== undefined) {
        // status = 'Inactive'
      }
      return {
        Id: qs.Id,
        Number: index + 1,
        Statement: qs.Statement,
        Content: qs.Content,
        Alternatives: theAlternatives,
        Type:
          qs.Type !== undefined ? qs.Type.replace(" ", "") : "MultipleChoice",
        Status: status,
        Tags: qs.Tags,
      };
    });

    let arrayIds = questionList.map((qs, index) => {
      return qs.Id;
    });

    let arrayQuestoes = [];
    if (embaralhar) {
      arrayIds = shuffle(arrayIds);
      let number = 1;
      for (let i = 0; i < arrayIds.length; i++) {
        for (let j = 0; j < questionList.length; j++) {
          if (questionList[j].Id === arrayIds[i]) {
            questionList[j].Number = number;
            arrayQuestoes.push(questionList[j]);
            number++;
          }
        }
      }
    } else {
      arrayQuestoes = questionList;
    }

    if (embaralharAlternatives) {
      for (let i = 0; i < arrayQuestoes.length; i++) {
        var changeOrderAlternatives;
        changeOrderAlternatives = arrayQuestoes[i].Alternatives.map(
          (item, index) => {
            return item.Order;
          }
        );
        changeOrderAlternatives = shuffle(changeOrderAlternatives);
        for (let j = 0; j < arrayQuestoes[i].Alternatives.length; j++) {
          arrayQuestoes[i].Alternatives[j].Order = changeOrderAlternatives[j];
        }
      }
    }

    if (editView) {
      if (id === null) {
        return {
          Title: title || "Prova Criada Online",
          Questions: arrayQuestoes,
          Tags: examTags,
        };
      } else {
        return {
          Title: title || "Prova Criada Online",
          Questions: arrayQuestoes,
          Tags: examTags,
        };
      }
    } else {
      return {
        Title: title || "Prova Criada Online",
        Subject: subject || null,
        Questions: arrayQuestoes,
        Tags: examTags,
      };
    }
  };

  const handleSave = async (status) => {
    let exam = newExam();
    let bok = true;
    if (isSuperAdmin) {
      if (escolas !== undefined && escolas.length > 0) {
        exam.Companies = escolas;
      } else {
        exam.Companies = [];
      }
    }
    if (bok) {
      setLoading(true);
      let newId;
      if (editView) {
        const query = new URLSearchParams(window.location.search);
        newId = query.get("examId");
        exam.Status = status;
        await updateExam(newId, exam);
        setExamId(newId);
      } else {
        exam.Status = status;
        newId = await createExam(exam);
        setExamId(newId);
        await updateExam(newId, exam);
        await finishExam(newId, exam);
      }
      setLoading(false);
      window.location.href = "/list-exams?save=True";
    } else {
      alert("Selecione escola(s)");
      return false;
    }
  };

  const backButton = (
    <StyledButton htmlType="button" onClick={onCancel}>
      Voltar
    </StyledButton>
  );

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(selectedQuestions);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setSelectedQuestions(items);
  };

  const displayQuestionStatement = (statement) => {
    const characterlimit = 300;
    const cleanStatement = removeHtmlTags(statement);
    return truncate(cleanStatement, characterlimit);
  };

  const truncate = (statement, limit) => {
    return statement.length > limit
      ? statement.slice(0, limit > 3 ? limit - 3 : limit) + "..."
      : statement;
  };

  const removeHtmlTags = (statement) => {
    return statement.replace(/\s*<.*?>\s*/g, "");
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={backButton}
      css={QuestionsModalStyle}
      destroyOnClose
    >
      <Spin spinning={loading}>
        {isSuperAdmin && (
          <Row>
            <div className={"col-sm-12"}>
              <StyledTitle>Escolas:</StyledTitle>
              <SelectCompanies
                onChange={handleSelectCompany}
                companies={selected.CompanyId}
              />
            </div>
          </Row>
        )}
        <Row>
          <div className={"col-sm-12"}>
            <StyledTitle>Nome da Prova:</StyledTitle>
            <StyledInput
              type="text"
              value={
                title != null
                  ? title
                  : selected !== undefined &&
                    selected !== null &&
                    selected.Title !== ""
                  ? selected.Title
                  : ""
              }
              onInput={handleUpdateTitle}
            />
            {
              user.Role === "Super" ||
              user.Role === "Master" &&
              <>
                <StyledTitle>Disciplina:</StyledTitle>
                <StyledSelect
                  className='select-input'
                  value={subject}
                  onChange={handleSubject}
                  showSearch
                  style={{ width: '100%' }}
                  placeholder={'Disciplina'}
                  allowClear
                >
                  {subjects.map((option, index) => (
                    <Option key={index} value={option}>
                      {option}
                    </Option>
                  ))}     
                </StyledSelect>
              </>
            }
          </div>
        </Row>
        <Row>
          <div className={"col-sm-12"}>
            <StyledTitle>Embaralhar Questões?</StyledTitle>{" "}
            <StyledCheckbox type="checkbox" onChange={handleUpdateEmbaralhar} />
          </div>
        </Row>
        {/* <Row>
          <div className={"col-sm-12"}>
            <StyledTitle>Embaralhar Alternativas?</StyledTitle>{" "}
            <StyledCheckbox type="checkbox" onChange={handleUpdateEmbaralharAlternatives} />
          </div>
        </Row> */}

        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="questions">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {selectedQuestions.map((question, index) => (
                  <Draggable
                    key={question.Id}
                    draggableId={question.Id}
                    index={index}
                  >
                    {(provided) => (
                      <QuestionCardContainer
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <QuestionTitle>{question.examTitle}</QuestionTitle>
                        <QuestionStatement>
                          {displayQuestionStatement(question.Statement)}
                        </QuestionStatement>
                        <RemoveButton
                          onClick={() =>
                            removeQuestion(question.examId, question)
                          }
                        >
                          Remover Questão
                        </RemoveButton>
                      </QuestionCardContainer>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {/* <Row>
          <Buttons>
            <StyledButton
              type="danger"
              onClick={removeAllQuestions}
              shape="round"
            >
              Remover Todas as Questões
            </StyledButton>
          </Buttons>
        </Row> */}
        <Row>
          <Buttons>
          <Grid container direction={isSmallScreen ? 'column' : 'row'} justifyContent={'center'}>
            {user && (
              user.Role === "Super" ||
              user.Role === "Master" ||
              user.Role === "District" ||
              (user.Role === "Teacher" && user.Coordinator === true) ||
              (user.Role === "Teacher" && user.SuperId === NH_SUPER_ID)) &&
              <StyledButton
                type="primary"
                onClick={() => handleSave("Active")}
                shape="round"
                style={{width: '250px'}}
              >
                Salvar e aprovar
              </StyledButton>
            }
              <StyledButton
                type="primary"
                onClick={() => handleSave("Draft")}
                shape="round"
                style={{width: '250px'}}
              >
                Salvar como rascunho
              </StyledButton>
            </Grid>
          </Buttons>
        </Row>
      </Spin>
    </Modal>
  );
};

SaveModal.propTypes = {
  id: PropTypes.string,
  selected: PropTypes.any,
  editView: PropTypes.bool,
  downloadFile: PropTypes.string,
  questions: PropTypes.array,
  inactiveQuestions: PropTypes.array,
  realQuestions: PropTypes.array,
  examTags: PropTypes.array,
  onCancel: PropTypes.func.isRequired,
  onShuffle: PropTypes.func,
  setExamId: PropTypes.func,
  visible: PropTypes.bool.isRequired,
  isSuperAdmin: PropTypes.bool.isRequired,
  cartQuestions: PropTypes.array,
  user: PropTypes.object,
  infoUser: PropTypes.func,
};

const mapStateToProps = ({ outputs, exams, users }) => ({
  hideWarnings: outputs.hideWarnings,
  loading: outputs.loading,
  downloadFile: exams.downloadFile,
  user: users.infoUser,
  id: exams.id,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchOutput,
      setExamId,
      finishExam,
      downloadExam,
      infoUser,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SaveModal);
