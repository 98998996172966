import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import history from '../../routes/history'
import * as yup from 'yup'
import { editCompany } from '../../store/companies/actions'
import { withFormik, Field } from 'formik'

import { Form, Spin } from 'antd'
import EmailInput from '../input/EmailInput'
import cep from 'cep-promise'
import TextInput from '../input/TextInput'
import SubmitButton from '../button/SubmitButton'
import { GroupLabel, StyledForm } from './styles/Form.styles'
import { StyledTitle } from '../../pages/styles/ListExamsPage.styles'
import SelectDistrict from '../input/SelectDistrict'
import { FORTALEZA_TESTE_SUPER_ID } from '../../../src/utils/constants'

const validationSchema = props => {
  return yup.object().shape({
    Name: yup.string().required(),
    Email: yup.string().email().required(),
    Address1: yup.string(),
    Address2: yup.string(),
    City: yup.string(),
    State: yup.string(),
    ZipCode: yup.string()
  })
}

const mapPropsToValues = ({ initialValues }) => {
  if (!initialValues || !Object.keys(initialValues).length) return initialValues
  return {
    ...initialValues
  }
}

const CompanyForm = ({
  isValid,
  loading,
  validation,
  // createCompany,
  editCompany,
  values,
  user
}) => {
  const cidadeInput = useRef({})
  const enderecoInput = useRef({})
  const estadoInput = useRef({})
  const handleCep = async (e) => {
    loading = true
    try {
      let data = await cep(e.target.value)
      if (data !== undefined && data !== null) {
        if (cidadeInput.current !== null) {
          cidadeInput.current.value = data.city
        }
        if (enderecoInput.current !== null) {
          enderecoInput.current.value = data.street
        }
        if (estadoInput.current !== null) {
          estadoInput.current.value = data.state
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <StyledForm>
      <Spin spinning={loading}>
        <GroupLabel>Informações Básicas</GroupLabel>
        <br />
        <StyledTitle>Nome:</StyledTitle>
        <Field
          name='Name'
          component={TextInput}
        />
        <StyledTitle>Email:</StyledTitle>
        <Field
          name='Email'
          component={EmailInput}
        />
        {user && user.Id === FORTALEZA_TESTE_SUPER_ID && (
          <>
            <StyledTitle>Distrito:</StyledTitle>
            <Field
              name='District'
              component={SelectDistrict}
            />
          </>
        )}
        <GroupLabel>Endereço</GroupLabel>
        <br />
        <StyledTitle>Cep:</StyledTitle>
        <Field
          name='Address[ZipCode]'
          onKeyUp={handleCep}
          component={TextInput}
        />
        <StyledTitle>Logradouro:</StyledTitle>
        <Field
          name='Address[Address1]'
          innerRef={enderecoInput}
          ref={enderecoInput}
          component={TextInput}
        />
        <StyledTitle>Cidade:</StyledTitle>
        <Field
          name='Address[City]'
          innerRef={cidadeInput}
          ref={cidadeInput}
          component={TextInput}
        />
        <StyledTitle>Estado:</StyledTitle>
        <Field
          name='Address[State]'
          innerRef={estadoInput}
          ref={estadoInput}
          component={TextInput}
        />
        <Form.Item>
          <SubmitButton
            disabled={!isValid}
            loading={loading}
          >
            Salvar
          </SubmitButton>
        </Form.Item>
      </Spin>
    </StyledForm>
  )
}

CompanyForm.propTypes = {
  isValid: PropTypes.bool,
  loading: PropTypes.bool,
  validation: PropTypes.object,
  values: PropTypes.object,
  // createCompany: PropTypes.func
  editCompany: PropTypes.func,
  user: PropTypes.object,
}

const mapStateToProps = ({ companies, auth }) => ({
  loading: companies.loading,
  validation: companies.validation,
  form: companies.form,
  user: auth.loggedUser
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    editCompany
  }, dispatch)

const handleSubmit = (values, formikBag) => {
  formikBag.props.editCompany(values.Id, values)
  history.push('/companies')
}

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    handleSubmit,
    validationSchema,
    enableReinitialize: true,
    mapPropsToValues
  })
)

export default enhance(CompanyForm)
