import React, { useState, useEffect } from "react";
import { StyledSelect } from "../question/QuestionFilterHeader/styles.js";
import { fetchClassStudents } from '../../store/companies/services'
import { Select } from "antd";

function SelectStudents({ onChange, schools, classes, clearStudents, setClearStudents }) {
    const [selected, setSelected] = useState([]);
    const [students, setStudents] = useState([]);
    const [allStudents, setAllStudents] = useState([]);
    const { Option } = Select;

    async function loadStudents(classes) {
        try {
            const response = await fetchClassStudents(schools, classes);
            const allStudentsIds = response.map(student => student.Id);
            setStudents(response);
            setAllStudents(allStudentsIds);
        } catch (error) {
            console.error("Failed to load students:", error);
        }
    }

    useEffect(() => {
        if (classes) {
            loadStudents(classes);
        }
    }, [classes]);

    useEffect(() => {
        if (clearStudents) {
            setSelected([]);
            setStudents([]);
            setClearStudents(false);
        }
    }, [clearStudents]);

    function handleChange(value) {
        if (value === '' || Array.isArray(value) && value.includes('')) {
            onChange(allStudents)
            setSelected([''])
        } else {
            onChange(value)
            setSelected(value)
        }
    }

    function renderOptions() {
        return (students || []).map((student) => (
            <Option key={student.Id} value={student.Id} title={student.Name}>
                {student.Name}
            </Option>
        ));
    }

    return (
        <StyledSelect
            mode={"multiple"}
            className="select-input"
            placeholder={"Selecione o(s) aluno(s)"}
            onChange={handleChange}
            filterOption={(input, option) => 
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={selected}
            style={{ width: "100%" }}
        >
            {allStudents.length > 1 && (
                <Option key={"all"} value={""} title={"Todos"}>
                    {"Todos"}
                </Option>
            )}
            {renderOptions()}
        </StyledSelect>
    );
}

export default SelectStudents;