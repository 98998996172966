/* eslint-disable */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import history from '../../routes/history'
import * as yup from 'yup'
import { createUser, updateUser, infoUser } from '../../store/users/actions'
import { withFormik, Field } from 'formik'
// import * as generate from 'generate-password'

import { Form, Spin, Checkbox } from 'antd'
import EmailInput from '../input/EmailInput'
import TextInput from '../input/TextInput'
import SubmitButton from '../button/SubmitButton'
import { GroupLabel, StyledRegisterForm } from './styles/Form.styles'
import SelectCompany from '../input/SelectCompany'
import SelectRoles from '../input/SelectRoles'
import SelectGrades from '../input/SelectGrades'
import SelectSubject from '../input/SelectSubject'
import PasswordInput from '../input/PasswordInput'
import SelectDistrict from '../input/SelectDistrict'
import { StyledButton } from '../button/styles/SubmitButton.styles'
import { StyledTitle } from '../../pages/styles/ListExamsPage.styles'
import { FORTALEZA_TESTE_SUPER_ID } from '../../../src/utils/constants'

const validationSchema = props => {
  if (['SuperUser', 'Super'].includes(props.user.Role)) {
    return yup.object().shape({
      Name: yup.string().required(),
      Email: yup.string().email().required(),
      Password: yup.string().required(),
      Profile: yup.string(),
      CompanyId: yup.string().when("Profile", {
        is:(profile) => (profile !== "Guest" && profile !== "District"),
        then: yup.string().required()
      }),
    })
  } else {
    if (props.user.Role === "Teacher") {
      return yup.object().shape({
        Name: yup.string().required(),
        Email: yup.string().email().required(),
      })
    }
      else if (props.user.Role === "District") {
        return yup.object().shape({
          Name: yup.string().required(),
          Email: yup.string().email().required(),
          Password: yup.string().required(),
          Profile: yup.string().required(),
        })
      }
    else {
      return yup.object().shape({
        Name: yup.string().required(),
        Email: yup.string().email().required(),
        Password: yup.string().required(),
      })
    }  
  }
}

const mapPropsToValues = ({ initialValues }) => {
  if (!initialValues || !Object.keys(initialValues).length) return initialValues
  return {
    ...initialValues
  }
}

const UserForm = ({
  isValid,
  loading,
  infoUser,
  user,
  validation,
  createUser,
  updateUser,
  values
}) => {
  const [createUpdate, setCreateUpdate] = useState(false);
  const [pressFinishButton, setPressFinishButton] = useState(false);
  const [allowView, setAllowView] = useState(false);
  const [disableField, setDisableField] = useState(false);
  
  useEffect(() => {
    if(!pressFinishButton) return;
    if(loading) {
      setCreateUpdate(true);
      return;
    }
    if(!createUpdate) return;
    history.push(`/users`);
  }, [loading, createUpdate, pressFinishButton]);

  function handleNewAction () {
    window.location.href = '/users'
  }

  useEffect(()=>{
    if (values.Profile !== "Teacher") {
      values.Coordinator = false;
      values.Subjects = [];
    }

    if (values.ViewableUserIds) {
      if (values.ViewableUserIds.includes(user.Id))
        setAllowView(true);
    }
  },[values])

  useEffect(()=> {
    if (user !== null && user.Role === 'Teacher')
      setDisableField(true);
  },[user])

  const handleAllowView = e => {
    const checked = e.target.checked;

    if (checked) {
      if (values.ViewableUserIds && values.ViewableUserIds.length > 0) {
        if (!values.ViewableUserIds.includes(user.Id))
          values.ViewableUserIds.push(user.Id);
      } else {
        values.ViewableUserIds = [user.Id];
      }
    } else {
      if (values.ViewableUserIds.includes(user.Id))
        values.ViewableUserIds = values.ViewableUserIds.filter(id => id !== user.Id);
    }
    
    setAllowView(e.target.checked);
  }

  return (
    <StyledRegisterForm>
      <Spin spinning={loading}>
        <GroupLabel>Informações Básicas</GroupLabel>
        <br />
        <StyledTitle>Nome:</StyledTitle>
        <Field
          disabled={disableField}
          name='Name'
          component={TextInput}
        />
        <StyledTitle>Email:</StyledTitle>
        <Field
          disabled={disableField}
          name='Email'
          component={EmailInput}
        />
        {(user !== null && (["SuperUser", "Super", "District"].includes(user.Role))) &&
        <>
          <StyledTitle>Senha:</StyledTitle>
          <Field
            name='Password'
            component={PasswordInput}
          />
        </>
        }
        {(user !== null && (user.Role === 'Master' || user.Role === 'Retail')) &&
        <>
          <StyledTitle>Senha:</StyledTitle>
          <Field
            name='Password'
            component={PasswordInput}
          />
        </>
        }
        {(user !== null && user.Role === 'Master') &&
        <>
          <StyledTitle>Perfil:</StyledTitle>
          <Field
            name='Profile'
            user={user}
            component={SelectRoles}
          />
        </>
        }
        {(user !== null && (["SuperUser", "Super", "District"].includes(user.Role))) &&
        <>
          <StyledTitle>Perfil:</StyledTitle>
          <Field
            name='Profile'
            user={user}
            component={SelectRoles}
          />
        </>
        }
        {(user !== null && (user.Id === FORTALEZA_TESTE_SUPER_ID || user.Role === "District") && values.Profile === "District") &&
          <>
            <StyledTitle>Distrito:</StyledTitle>
            <Field
              name='District'
              component={SelectDistrict}
              initialValue={user.District || "D1"}
              disabled={user.Role === "District" ? true : false}
            />
          </>
        }
        {(user !== null && values.Profile === "Teacher" && user.Role !== "Teacher") &&
          <div style={{marginBottom: "20px"}}>
            <StyledTitle>Definir como Coordenador:</StyledTitle>
            <Field
              name='Coordinator'
              type='checkbox'
              checked={values.Coordinator ? values.Coordinator : false}
              style={{
                transform: 'scale(1.5)',
                marginLeft:'15px'
              }}
            />
          </div>
        }
        {(user !== null && values.Profile === "Teacher") &&
          <div style={{marginBottom: "20px"}}>
            <StyledTitle>{`Permitir visualizar conteúdo do ${user.Coordinator ? "Coordenador" : user.Role}:`}</StyledTitle>
            <Checkbox 
              checked={allowView}
              onChange={handleAllowView}
              style={{
                transform: 'scale(1.3)',
                marginLeft:'15px',
              }}
            /> 
          </div>
        }
        {(user !== null && values.Profile === "Teacher" && user.Role === "Master") &&
          <div style={{marginBottom: "20px"}}>
            <StyledTitle>Desabilitar busca por questões em Montar:</StyledTitle>
            <Field
              name='DisableFilterByQuestion'
              type='checkbox'
              checked={values.DisableFilterByQuestion ? values.DisableFilterByQuestion : false}
              style={{
                transform: 'scale(1.5)',
                marginLeft:'15px'
              }}
            />
          </div>
        }
        {(user !== null && values.Profile === "Teacher") &&
        <>
          <StyledTitle>Disciplina:</StyledTitle>
          <Field
            disabled={disableField}
            name='Subject'
            user={user}
            component={SelectSubject}
          />
        </>
        }
        {(user !== null && (["SuperUser", "Super", "District"].includes(user.Role)) && values.Profile !== 'Super'  && values.Profile !== 'Guest' && values.Profile !== 'District') &&
        <>
          <StyledTitle>Empresa:</StyledTitle>
          <Field
            name='CompanyId'
            component={SelectCompany}
          />
        </>
        }
        {values ? (user !== null && (user.Role === 'Master' || user.Role === 'Professor') && (values.Profile === 'Student' || values.Profile === 'Teacher') || user.Role === 'Retail') &&
          <>
            <StyledTitle>Turmas:</StyledTitle>
            <Field
              name='Grades'
              component={SelectGrades}
            />
          </> : null
        }
        {(user !== null && user.Role === 'Student') ? (user !== null && (user.Role === 'Master' || user.Role === 'Professor') && values.Profile === 'Student') &&
          <>
            <StyledTitle>Turmas:</StyledTitle>
            <Field
              name='Grades'
              component={SelectGrades}
            />
          </> : null
        }
        <Form.Item>
          <SubmitButton
            disabled={user.Role === "Teacher" ? !disableField : !isValid}
            loading={loading}
            onClick={() => setPressFinishButton(true)}
          >
              Salvar
          </SubmitButton>
        </Form.Item>
        <Form.Item>
          <StyledButton
            type='default'
            htmlType='button'
            onClick={handleNewAction}
          >
            Voltar
          </StyledButton>
        </Form.Item>
      </Spin>
    </StyledRegisterForm>
  )
}

UserForm.propTypes = {
  isValid: PropTypes.bool,
  loading: PropTypes.bool,
  validation: PropTypes.object,
  values: PropTypes.object,
  infoUser: PropTypes.func,
  createUser: PropTypes.func,
  user: PropTypes.object,
  updateUser: PropTypes.func
}

const mapStateToProps = ({ users, auth }) => ({
  user: auth.loggedUser,
  loading: users.loading,
  validation: users.validation,
  form: users.form
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    infoUser,
    createUser,
    updateUser
  }, dispatch)

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    handleSubmit: (values, { resetForm, props, user }) => {
      if (values.Profile === undefined || values.Profile === null) {
        values.Profile = 'Teacher'
      }

      if (values.CompanyId === undefined || values.CompanyId === null) {
        values.CompanyId = props.user.CompanyId
      }

      if (props.user.Role === 'District') {
        values.District = props.user.District
      }

      if (values.Id === null || values.Id === undefined || values.Id === '') {
        props.createUser(values)
      } else {
        props.updateUser(values)
      }
      resetForm({})
    },
    validationSchema,
    enableReinitialize: true,
    mapPropsToValues
  })
)

export default enhance(UserForm)
