/** @jsx jsx */
import React, { useEffect, useRef, useState } from 'react';
import { jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from "powerbi-client";
import authorization from "./authorization.json";
import "./styles.css";
import { Spin, message } from 'antd';
import { getEmbedToken } from '../../store/users/services';
import mapping from './schools.json';

const PowerBiComponent = ({
  user,
  typeReport = "exams"
}) => {
  const [groupId, setGroupId] = useState(null);
  const [reportId, setReportId] = useState(null);
  const [embedUrl, setEmbedUrl] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [token, setToken] = useState(null);
  const [filters, setFilters] = useState({});
  const [allowed, setAllowed] = useState(false);
  const embedRef = useRef();

  useEffect(() => {
    if(loaded) return;
    if(reportId != null && embedUrl != null) return;
    const powerbiInfo = authorization[typeReport];
    let key;
    if(Object.keys(powerbiInfo).includes(user.SuperId)) {
      key = user.SuperId;
    } else if(Object.keys(powerbiInfo).includes(user.Id)) {
      key = user.Id;
    } else {
      message.info("Você não possui acesso a essa área, entre em contato com a administração", 10);
      key = "default";
    }
    setGroupId(powerbiInfo[key].groupId);
    setReportId(powerbiInfo[key].reportId);
    setEmbedUrl(powerbiInfo[key].embedUrl);
    if(key === "default") {
      setAllowed(true);
      return;
    }
    setPowerBiFiltersBasedOnRole(powerbiInfo[key]);
  }, [embedUrl, reportId]);

  useEffect(() => {
    if(!reportId || !groupId || !allowed) return;
    saveNewToken();
  }, [reportId, groupId, allowed]);

  const handleChangePage = async () => {
    const page = await embedRef.current.getActivePage();
    const pageName = page.displayName;
    var reportFilters = "Report" in filters ? filters["Report"] : [];
    if(pageName in filters) {
      const pageFilters = filters[pageName];
      reportFilters.push(...pageFilters);
    }
    await page.updateFilters(models.FiltersOperations.ReplaceAll, reportFilters)
  }

  const saveNewToken = async () => {
    const token = await getNewToken();
    if(!token) {
      setLoaded(true);
      message.error("Houve um erro interno, tente novamente mais tarde");
      return;
    }
    message.success("Seu dashboard será carregado em breve");
    setToken(token);
    setLoaded(true);
  };

  const getNewToken = async () => {
    try{
      const newEmbedToken = await getEmbedToken(reportId, groupId);
      if(!newEmbedToken) {
        message.error("Houve um erro interno, tente novamente mais tarde");
        return null;
      }
      return newEmbedToken;
    } catch (err) {
      message.info("Erro ao gerar novo token, tente novamente mais tarde", 10);
      setLoaded(true);
      console.log("error: ", err);
    }
  };

  const setPowerBiFiltersBasedOnRole = (powerbiInfo) => {
    if (!user.Role || !["Super", "District", "Master", "Teacher", "Guest"].includes(user.Role)) return;
  
    if (user.Role === "Super" || user.Role === "Guest") {
      setAllowed(true);
      return;
    }
  
    const filtersPowerbi = powerbiInfo.filters;
    if (!filtersPowerbi) {
      setAllowed(true);
      return;
    }
  
    var hashmapFilters = [];
    for (const filter of filtersPowerbi) {
      const { table, schoolColumn, turmaColumn, districtColumn } = filter;
      if (!table || !schoolColumn || !turmaColumn) continue;
  
      const company = user.Company;
      const grades = user.Grades;
      if (!company || (!grades && user.Role === "Teacher")) continue;
  
      let companyInPowerBi = company;
      const pages = filter.pages ? filter.pages : ["Report"];
  
      if (typeReport === 'preschool') {
        companyInPowerBi = mapping[company] || company;
      }

      const districtFilters = getDistrictFilter(table, districtColumn);
      hashmapFilters = addFiltersToHashmap(districtFilters, hashmapFilters, pages)
  
      const schoolFilter = getSchoolFilter(table, schoolColumn, companyInPowerBi);
      hashmapFilters = addFiltersToHashmap(schoolFilter, hashmapFilters, pages)

      const gradeFilters = getGradeFilter(table, turmaColumn, grades);
      hashmapFilters = addFiltersToHashmap(gradeFilters, hashmapFilters, pages)
    }
  
    if(Object.keys(hashmapFilters).length === 0) return;
    setFilters(hashmapFilters);
    setAllowed(true);
  };

  const addFiltersToHashmap = (newFilters, hashmap, pages = ["Report"]) => {
    for(const page of pages) {
      if(!(page in hashmap)) hashmap[page] = []
      hashmap[page].push(...newFilters)
    }
    return hashmap;
  };

  const getDistrictFilter = (table, districtColumn) => {
    const filters = [];
    if(user.Role === "District" && districtColumn) {
      filters.push({
        "$schema": "http://powerbi.com/product/schema#basic",
        "target": {
          "table": table,
          "column": districtColumn
        },
        "operator": "In",
        "values": [user.District],
        "filterType": 1
      });
    }

    return filters;
  }

  const getSchoolFilter = (table, schoolColumn, companyInPowerBi) => {
    const filters = []
    if (["Master", "Teacher"].includes(user.Role)) {
      filters.push({
        "$schema": "http://powerbi.com/product/schema#basic",
        "target": {
          "table": table,
          "column": schoolColumn
        },
        "operator": "In",
        "values": [companyInPowerBi],
      });
    }
    return filters;
  }

  const getGradeFilter = (table, turmaColumn, grades) => {
    const filters = [];
    if (user.Role === "Teacher") {
      filters.push({
        "$schema": "http://powerbi.com/product/schema#basic",
        "target": {
          "table": table,
          "column": turmaColumn
        },
        "operator": "In",
        "values": grades,
      });
    }

    return filters;
  }

  return (
    <div style={{ width: "100%", maxWidth: "4000px", margin: "0 auto" }}>
      <Spin spinning={!loaded}>
        {
          loaded &&
          <PowerBIEmbed
            embedConfig = {{
              type: 'report',
              id: reportId,
              embedUrl: embedUrl,
              accessToken: token,
              tokenType: models.TokenType.Embed,
              settings: {
                panes: {
                  filters: {
                    expanded: false,
                    visible: false
                  }
                },
              },
            }}

            eventHandlers = {
              new Map([
                ['loaded', function () {console.log('Report loaded');}],
                ['rendered', function () {console.log('Report rendered');}],
                ['error', function (event) {console.log(event.detail);}],
                ['visualClicked', () => console.log('visual clicked')],
                ['pageChanged', async (event) => await handleChangePage(event.detail.displayName)],
              ])
            }

            cssClassName = { "powerbi-container" }

            getEmbeddedComponent = { (embeddedReport) => {
              embedRef.current = embeddedReport;
            }}
          />
        }
      </Spin>
    </div>
  );
}

PowerBiComponent.propTypes = {
  user: PropTypes.object,
  typeReport: PropTypes.string
};

export default PowerBiComponent;