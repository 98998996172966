import React, { Component } from "react";
import PropTypes from "prop-types";
import { Select, Spin } from "antd";
import ValidatedSharedInput from "./ValidatedSharedInput";
import { handleBlur, handleChange } from "../../utils/formHelpers";
import { getRoles, getClasses } from "../../store/auth/services";
import { StyledSelect } from "../question/QuestionFilterHeader/styles";
import { FORTALEZA_TESTE_SUPER_ID } from "../../utils/constants";

class SelectRoles extends Component {
  state = {
    initialValue: this.props.initialValue,
    loading: false,
    timeout: false,
    rolesOptions: [],
    input: "",
  };

  static propTypes = {
    label: PropTypes.string.isRequired,
    field: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    user: PropTypes.object,
    initialValue: PropTypes.string,
    search: PropTypes.bool,
  };

  rolesOptions() {
    let dados = [];
    let first;
    if (this.props.label === "Turma") {
      getClasses().then((classes) => {
        classes &&
          classes.Classes &&
          classes.Classes.map((j) => {
            let option = (
              <Select.Option key={j} value={j} title={j}>
                {j}
              </Select.Option>
            );
            dados.push(option);
            return option;
          });
      });
    } else {
      getRoles().then((roles) => {
        roles.map((j) => {
          if (j === "Admin") {
            j = "Master";
          }
          if (first === undefined) {
            first = j;
          }
          let option = (
            <Select.Option key={j} value={j} title={j}>
              {j}
            </Select.Option>
          );
          if (this.props.user.Role === "Master") {
            if (j === "Teacher" || j === "Student") {
              dados.push(option);
            }
          } else if (this.props.user.Role === "Super") {
            const isFortalezaSuper = this.props.user.Id === FORTALEZA_TESTE_SUPER_ID;
            const profileOptions = isFortalezaSuper ? ["Master", "Guest", "District"] : ["Master", "Guest"];
            if (profileOptions.includes(j)) {
              dados.push(option);
            }
          } else if (this.props.user.Role === "District") {
            const profileOptions = ["Master", "Teacher", "Student", "District"];
            if (profileOptions.includes(j)) {
              dados.push(option);
            }
          } else {
            dados.push(option);
          }
          return option;
        });
      });
    }
    this.setState({ rolesOptions: dados });
  }

  rolesOptionsSearch(value) {
    let dados = [];
    getRoles().then((roles) => {
      roles.map((j) => {
        if (j === "Admin") {
          j = "Master";
        }
        let option = (
          <Select.Option key={j} value={j} title={j}>
            {j}
          </Select.Option>
        );

        if (this.props.user.Role === "Master") {
          if (j === "Teacher" || j === "Student") {
            dados.push(option);
          }
        } else {
          dados.push(option);
        }
        return option;
      });
    });

    this.setState({ rolesOptions: dados });
  }

  componentDidMount() {
    this.rolesOptions();
  }

  componentDidUpdate(prevProps) {
    const { initialValue } = this.props;
    prevProps.initialValue !== initialValue && this.setState({ initialValue });
  }

  render() {
    const { initialValue, loading, rolesOptions } = this.state;
    const { label, field, form, search } = this.props;

    return (
      <ValidatedSharedInput
        touched={form.touched[field.name]}
        error={form.errors[field.name]}
        label={label}
      >
        <StyledSelect
          className="select-input"
          value={initialValue || field.value}
          onBlur={() => handleBlur(form, field)}
          onChange={(value) =>
            this.setState({ initialValue: "" }, () =>
              handleChange(form, field, value)
            )
          }
          showSearch={search}
          onSearch={this.handleSearch}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          notFoundContent={
            loading ? <Spin spinning size="small" /> : "Nada encontrado"
          }
          placeholder={this.props.label}
          loading={loading}
          allowClear
        >
          {rolesOptions}
        </StyledSelect>
      </ValidatedSharedInput>
    );
  }

  handleSearch = (input) => {
    clearTimeout(this.state.timeout);
    const timeout = setTimeout(() => this.rolesOptionsSearch({ input }), 400);

    this.setState({ input, timeout, loading: false });
  };
}

export default SelectRoles;
