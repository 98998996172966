import React, { useState, useEffect } from "react";
import { StyledSelect } from "../question/QuestionFilterHeader/styles.js";
import { fetchCompanyClasses } from '../../store/companies/services.js'
import { Select } from "antd";

function SelectClasses({ onChange, onLoaded, schools = [] }) {
    const [selected, setSelected] = useState([]);
    const [classes, setClasses] = useState([]);
    const { Option } = Select;

    useEffect(() => {
        if (schools.length !== 0) {
            loadOptions(schools);
        }
    }, [schools]);
    
    async function loadOptions(schools) {
        const classrooms = await fetchCompanyClasses(schools || []);
        setClasses(classrooms);
        if (onLoaded) {
          onLoaded(classrooms);
        }
      }
    
    function handleChange(value) {
        if (value === '' || Array.isArray(value) && value.includes('')) {
            onChange(classes);
            setSelected(['']);
        } else {
            onChange(value);
            setSelected(value);
        }
    }
    
    function renderOptions() {
        return (classes || []).map((classroom) => (
            <Option key={classroom} value={classroom} title={classroom}>
                {classroom}
            </Option>
        ));
    }
    
    return (
        <StyledSelect
            mode={"multiple"}
            className="select-input"
            placeholder={"Selecione a(s) turma(s)"}
            onChange={handleChange}
            filterOption={(input, option) => 
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={selected}
            style={{ width: "100%" }}
        >
            {classes.length > 1 && (
                <Option key={""} value={""} title={"Todas"}>
                    {"Todas"}
                </Option>
            )}
            {renderOptions()}
        </StyledSelect>
    );
}

export default SelectClasses;